// sass-lint:disable-all
.select2 {
	width: 100% !important;
}

.select2-container {
box-sizing: border-box;
display: inline-block;
margin: 0;
position: relative;
vertical-align: middle; 
}
.select2-container .select2-selection--single {
box-sizing: border-box;
cursor: pointer;
display: block;
height: 28px;
user-select: none;
-webkit-user-select: none; }
.select2-container .select2-selection--single .select2-selection__rendered {
display: block;
padding-left: 8px;
padding-right: 20px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap; }
.select2-container .select2-selection--single .select2-selection__clear {
position: relative; }
.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
padding-right: 8px;
padding-left: 20px; }
.select2-container .select2-selection--multiple {
box-sizing: border-box;
cursor: pointer;
display: block;
min-height: 32px;
user-select: none;
-webkit-user-select: none; }
.select2-container .select2-selection--multiple .select2-selection__rendered {
display: inline-block;
overflow: hidden;
padding-left: 8px;
text-overflow: ellipsis;
white-space: nowrap; }
.select2-container .select2-search--inline {
float: left; }
.select2-container .select2-search--inline .select2-search__field {
box-sizing: border-box;
border: none;
font-size: 100%;
margin-top: 5px;
padding: 0; }
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
-webkit-appearance: none; }

.select2-dropdown {
background-color: #fff;
box-sizing: border-box;
display: block;
position: absolute;
left: 0;
right: 0;
width: 100%;
z-index: 1051; 
}

.select2-results {
display: block; }

.select2-results__options {
list-style: none;
background-color: transparent;
margin-bottom: 2px;
margin: 0;
padding: 0; }

.select2-results__option {
padding: 6px;
transition: background-color ease .4s;
user-select: none;
-webkit-user-select: none;
color: #787878 !important; }

.select2-results__option:hover{
color: #fff !important;
}
.select2-results__option[aria-selected] {   
cursor: pointer;
background-color: map-get($colors, green);
transition: background-color ease .4s;
margin-bottom: 1px;
color: #fff !important;
padding-left: 20px;
letter-spacing: 0.1em;
font-size: 13px;

	@include media(sm) {
		font-size: 14px;
	}

	@include media(def) {
	    font-size: 16px;
	}
}

.select2-results__option[aria-selected]:hover{
background-color: #000 !important;
color: #fff;
}

.select2-container--open .select2-dropdown {
left: 0; }

.select2-container--open .select2-dropdown--above {
border-bottom: none;
border-bottom-left-radius: 0;
border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
border-top: none;
border-top-left-radius: 0;
border-top-right-radius: 0;
backface-visibility: hidden; }

.select2-search--dropdown {
display: block;
padding: 4px; }
.select2-search--dropdown .select2-search__field {
padding: 4px;
width: 100%;
box-sizing: border-box; }
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
-webkit-appearance: none; }
.select2-search--dropdown.select2-search--hide {
display: none; }

.select2-close-mask {
border: 0;
margin: 0;
padding: 0;
display: block;
position: fixed;
left: 0;
top: 0;
min-height: 100%;
min-width: 100%;
height: auto;
width: auto;
opacity: 0;
z-index: 99;
background-color: #fff;
filter: alpha(opacity=0); }

.select2-hidden-accessible {
border: 0 !important;
clip: rect(0 0 0 0) !important;
-webkit-clip-path: inset(50%) !important;
clip-path: inset(50%) !important;
height: 1px !important;
overflow: hidden !important;
padding: 0 !important;
position: absolute !important;
width: 1px !important;
white-space: nowrap !important; }

.select2-container--default .select2-selection--single {
background-color: transparent;
border: 1px solid #000;
height: 40px;
font-size: 13px;
letter-spacing: 0.1em;
display: flex;
text-transform: uppercase;
align-items: center;

@include media(sm) {
	font-size: 14px;
	height: 40px;
}

@include media(def) {
	height: 48px; 
	font-size: 16px;
}

}
.select2-container--default .select2-selection--single .select2-selection__rendered {
color: #000 !important;
padding: 0 0 0 0; }
.select2-container--default .select2-selection--single .select2-selection__clear {
cursor: pointer;
float: right;
font-weight: bold; }
.select2-container--default .select2-selection--single .select2-selection__placeholder {
color: #999; }
.select2-container--default .select2-selection--single .select2-selection__arrow {
width: 12px;
height: 12px;
border: 1px solid map-get($colors, dark);
border-left-color: transparent;
border-top-color: transparent;
border-radius: 3px;
position: absolute;
right: 19px;
top: 45%;
transform: translateY(-50%) rotate(45deg);
transition: transform ease .4s, top ease .4s;

@include media(sm) {
	width: 15px;
	height: 15px;
}

@include media(def) {
	width: 18px;
	height: 18px;
}
}

.select2-container--open .select2-selection__arrow{
top: 63%!important;
transform: translateY(-50%) rotate(225deg)!important;
transition: transform ease .4s, top ease .4s!important;
border-color: map-get($colors, green)!important;
border-left-color: transparent!important;
border-top-color: transparent!important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
height: 9px;
left: 50%;
margin-left: -4px;
margin-top: -2px;
position: absolute;
top: 50%;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
left: 1px;
right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
background-color: #eee;
cursor: default; }
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
border-color: transparent transparent #888 transparent;
border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
background-color: white;
border: 1px solid #aaa;
border-radius: 4px;
cursor: text; }
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
box-sizing: border-box;
list-style: none;
margin: 0;
padding: 0 5px;
width: 100%; }
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
list-style: none; }
.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
color: #999;
margin-top: 5px;
float: left; }
.select2-container--default .select2-selection--multiple .select2-selection__clear {
cursor: pointer;
float: right;
font-weight: bold;
margin-top: 5px;
margin-right: 10px; }
.select2-container--default .select2-selection--multiple .select2-selection__choice {
background-color: #e4e4e4;
border: 1px solid #aaa;
border-radius: 4px;
cursor: default;
float: left;
margin-right: 5px;
margin-top: 5px;
padding: 0 5px; }
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
color: #999;
cursor: pointer;
display: inline-block;
font-weight: bold;
margin-right: 2px; }
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
color: #333; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
margin-left: 5px;
margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
margin-left: 2px;
margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
border: solid black 1px;
outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
background-color: #eee;
cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
border-top-left-radius: 0;
border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
border-bottom-left-radius: 0;
border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
background: transparent;
border: none;
outline: 0;
box-shadow: none;
-webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
max-height: 200px;
overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
    background-color: #a3a2a2;
    padding-left: 17px;
    color: #fff; }

.select2-container--default .select2-results__option[aria-disabled=true]:hover{
color: #787878 !important;
}

.select2-container--default .select2-results__option[aria-selected=true] {
background-color: #000 !important; 
color: #fff !important;
}

.select2-container--default .select2-results__option .select2-results__option {
padding-left: 1em; }
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
padding-left: 0; }
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
margin-left: -1em;
padding-left: 2em; }
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
margin-left: -2em;
padding-left: 3em; }
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
margin-left: -3em;
padding-left: 4em; }
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -5em;
    padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
background-color: #1e1e1e;
color: #787878; }

.select2-container--default .select2-results__group {
cursor: default;
display: block;
padding: 6px; }

.select2-container--classic .select2-selection--single {
background-color: #f7f7f7;
border: 1px solid #aaa;
border-radius: 4px;
outline: 0;
background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
background-repeat: repeat-x;
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }
.select2-container--classic .select2-selection--single:focus {
border: 1px solid #5897fb; }
.select2-container--classic .select2-selection--single .select2-selection__rendered {
color: #444;
line-height: 28px; }
.select2-container--classic .select2-selection--single .select2-selection__clear {
cursor: pointer;
float: right;
font-weight: bold;
margin-right: 10px; }
.select2-container--classic .select2-selection--single .select2-selection__placeholder {
color: #999; }
.select2-container--classic .select2-selection--single .select2-selection__arrow {
background-color: #ddd;
border: none;
border-left: 1px solid #aaa;
border-top-right-radius: 4px;
border-bottom-right-radius: 4px;
height: 26px;
position: absolute;
top: 1px;
right: 1px;
width: 20px;
background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
background-repeat: repeat-x;
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }
.select2-container--classic .select2-selection--single .select2-selection__arrow b {
border-color: #888 transparent transparent transparent;
border-style: solid;
border-width: 5px 4px 0 4px;
height: 0;
left: 50%;
margin-left: -4px;
margin-top: -2px;
position: absolute;
top: 50%;
width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
border: none;
border-right: 1px solid #aaa;
border-radius: 0;
border-top-left-radius: 4px;
border-bottom-left-radius: 4px;
left: 1px;
right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
border: 1px solid #5897fb; }
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
background: transparent;
border: none; }
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
border-color: transparent transparent #888 transparent;
border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
border-top: none;
border-top-left-radius: 0;
border-top-right-radius: 0;
background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
background-repeat: repeat-x;
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
border-bottom: none;
border-bottom-left-radius: 0;
border-bottom-right-radius: 0;
background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
background-repeat: repeat-x;
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
background-color: white;
border: 1px solid #aaa;
border-radius: 4px;
cursor: text;
outline: 0; }
.select2-container--classic .select2-selection--multiple:focus {
border: 1px solid #5897fb; }
.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
list-style: none;
margin: 0;
padding: 0 5px; }
.select2-container--classic .select2-selection--multiple .select2-selection__clear {
display: none; }
.select2-container--classic .select2-selection--multiple .select2-selection__choice {
background-color: #e4e4e4;
border: 1px solid #aaa;
border-radius: 4px;
cursor: default;
float: left;
margin-right: 5px;
margin-top: 5px;
padding: 0 5px; }
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
color: #888;
cursor: pointer;
display: inline-block;
font-weight: bold;
margin-right: 2px; }
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
color: #555; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
float: right;
margin-left: 5px;
margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
margin-left: 2px;
margin-right: auto; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
border-top: none;
border-top-left-radius: 0;
border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
border-bottom: none;
border-bottom-left-radius: 0;
border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
border: 1px solid #aaa;
outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
outline: 0;
box-shadow: none; }

.select2-container--classic .select2-dropdown {
background-color: white;
border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
max-height: 200px;
overflow-y: auto; }

.select2-container--classic .select2-results__option[role=group] {
padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled=true] {
color: grey; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
background-color: #3875d7;
color: white; }

.select2-container--classic .select2-results__group {
cursor: default;
display: block;
padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
border-color: #5897fb; }

.select2-selection.select2-selection--single{
position: relative;
overflow: hidden;
padding-left: 20px;
}

span {
	&:focus {
		outline: none;
	}
}



.form-contact-block .select2-container--default .select2-selection--single {

	font-size: 14px;
	letter-spacing: 0.2em;
	text-transform: none;
	
	@include media(def) {
		font-size: 15px;
		letter-spacing: 0.2em;
	}
}

.form-contact-block .select2-results__option[aria-selected] {
	font-size: 10px;
	letter-spacing: 0.2em;

	@include media(sm) {
		font-size: 10px;
		letter-spacing: 0.2em;
	}
	
	@include media(def) {
		font-size: 10px;
		letter-spacing: 0.2em;
	}
}


.form-group__input, .form-group__textarea, .form-group__select{

 	@include media(ms) {
   		height: 40px ;
   }
   
   @include media(def) {
   		height: 42px;
   }

   @include media(xl) {
   		height: 49px;
   }
}

.form-contact-block .select2-container--default .select2-selection--single {

 	@include media(ms) {
   		height: 40px !important;
   }
   
   @include media(def) {
   		height: 42px !important;
   }

   @include media(xl) {
   		height: 49px !important;
   }
}

.mCSB_scrollTools {
	width: 12px;
	background-color: map-get($colors, dark);
}

.mCSB_scrollTools .mCSB_draggerRail {
	background-color: #000!important;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	background-color: map-get($colors, green-dark)!important;
    margin: 15px auto 0;
    border-radius: 2px;
    width: 4px;
}

.mCSB_inside > .mCSB_container {
	margin-right: 12px;
}

.select2-container--default .select2-results > .select2-results__options{
	max-height: auto!important;
	overflow: visible!important;
}

.select2-results{
	max-height: 200px;
}

.left-block {

	.mCSB_scrollTools {
		background-color: transparent;
	}

	.mCSB_scrollTools .mCSB_draggerRail {
	    background-color: transparent !important;
	}

	.mCSB_scrollTools {
		top: -15px;
		left: 0;
		right: auto;
	}

	.mCustomScrollBox {
		padding-left: 15px;
	}

	.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	    width: 2px;
	}
}

// .select2-selection.select2-selection--single:before{
// content: '';
// position: absolute;
// display: block;
// left: 0;
// width: 100%;
// height: 3px;
// background-color: #f18700;
// bottom:-2px;
// transform: scaleX(0);
// left: 0;
// transition: transform ease .7s;
// transform-origin: left!important;
// }

// .select2-selection.select2-selection--single[aria-expanded="false"]:before{
// transform: scaleX(1);
// transition: transform ease .7s;
// }