.bg-block {
	position: relative;
	display: flex;
	flex-direction: column;
	background-repeat: no-repeat;
	max-width: 1280px;
	padding: vw(125px) 0 0 vw(61px);
	margin-bottom: vw(150px);

	@include media(def) {
		padding: vw(225px) 0 0 vw(115px);
	}

	.akcii-item {
		margin-bottom: vw(307px);

		@include media(def) {
			margin-bottom: vw(115px);
		}
	}
}
