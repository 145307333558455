.learn-main {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	margin-top: 145px;
	flex-direction: column;
	padding-left: 15px;
	padding-right: 15px;


	@include media(ms) {
		width: 100%;
		margin-top: vw(201px);
	}

	@include media(def) {
		padding-left: vw(146px);
		padding-right: 15px;
	}

	@include media(mg) {
		padding-right: 0;
	}

	&__list {
		margin: rem(49px) 0 rem(96px);
	}

	&__item {
		max-width: 1280px;
		background-color: map-get($colors, blue_light);
		padding: rem(69px) rem(25px) rem(46px);
		text-align: center;
		margin-bottom: rem(40px);
		text-transform: uppercase;
		color: #333;
		position: relative;
		transition: background-color ease 0.7s;

		&:hover {
			background-color: map-get($colors, salat-dark);
			transition: background-color ease 0.7s;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__place {
		font-size: rem(14px);
		line-height: rem(24px);
		letter-spacing: 0.4em;
	}

	&__date {
		@extend .learn-main__place;
		margin-bottom: rem(39px);
		letter-spacing: 0.2em;
	}

	&__name {
		max-width: 885px;
		margin: 0 auto;
		font: {
			size: rem(22px);
			weight: 500;
		};
		text-transform: uppercase;
		letter-spacing: 0.2em;
		color: #000;
		line-height: rem(32px);
		margin-bottom: 23px;

		@include media(def) {
			font-size: vw(22px);
			line-height: vw(32px);
		}

		@include media(lg) {
			font-size: vw(30px);
			line-height: vw(40px);
		}
	}

	&__text {
		max-width: 775px;
		margin: 0 auto;
		text-transform: none;
		font-size: rem(18px);
		line-height: rem(30px);
		color: #333;
		margin-bottom: rem(74px);
	}
}


.selects-block {
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: 40px;
	grid-row-gap: 15px;

	@include media(ms) {
		grid-template-columns: 1fr 1fr;
		grid-row-gap: 0;
	}

	&--learn-page {
		max-width: 960px;

		padding-left: 0;

		@include media(def) {
			margin-left: vw(160px);
		}
	}
}
