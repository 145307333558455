.akcii-item {
	max-width: 1120px;
	display: block;
	grid-template-columns: vw(201px) vw(231px) vw(230px);

	@include media(ms) {
		display: grid;
	}

	@include media(def) {
		grid-template-columns: vw(248px) vw(232px) vw(230px);
	}

	&--page-item {
		display: grid;
		grid-template-columns: vw(5px) vw(96px) vw(1675px);

		@include media(ms) {
			grid-template-columns: vw(350px) vw(231px) vw(230px);
		}
	}

	&__main {
		width: 89.2%;
		grid-column-start: 3;
		z-index: 2;
		grid-row-start: 1;
		margin-top: 0;
		align-self: end;
		position: relative;


		@include media(ms) {
			width: vw(940px);
			margin-top: vw(200px);
		}

		@include media(def) {
			width: vw(640px);
			margin-top: vw(104px);
		}
	}

	&__info {
		background-color: map-get($colors, light);
		text-transform: uppercase;
		color: #333;
		letter-spacing: 0.2em;
		padding: vw(51px) vw(50px) vw(26px);
		width: 102%;

		&--page-item {
			margin-top: 159px;

			@include media(ms) {
				margin-top: 20px;
			}

			@include media(def) {
				margin-top: vw(130px);
			}
		}
	}

	&__picture {
		position: relative;
		width: vw(1400px);
		height: vw(750px);
		grid-column-start: 1;
		grid-row-start: 1;

		@include media(ms) {
			height: vw(1200px);
		}


		@include media(def) {
			width: vw(640px);
			height: vw(580px);
		}

		&--page-item {
			display: block;
			width: vw(1400px);
			height: vw(1270px);

			@include media(ms) {
				width: vw(740px);
				height: vw(680px);
			}
		}

	}

	&__date {
		font-size: rem(10px);
		letter-spacing: 0.2em;
	}

	&__text {
		display: block;
		font: {
			size: rem(18px);
			weight: 500;
		};
		color: map-get($colors, dark);
		line-height: 22px;
		padding: rem(22px) 0;
		transition: color ease 0.7s;

		@include media(ms) {
			padding: vw(37px) 0;
		}

		@include media(def) {
			font-size: vw(30px);
			line-height: vw(40px);
		}

		&:hover {
			color: map-get($colors, green-dark);
			transition: color ease 0.7s;
		}
	}
}
