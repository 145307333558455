.where-main {
	max-width: 977px;
	margin: 0 auto vw(90px);
	display: flex;
	flex-direction: column;

	&__help {
		font-size: 15px;
		text-align: center;
		letter-spacing: 0.2em;
		text-transform: uppercase;
		margin-bottom: vw(43px);
	}

	@include media(def) {
		margin-left: vw(296px);
	}

	@include media(mg) {
		margin: 0 auto vw(27px);
	}
}


.where-buy {
	max-width: 960px;
	margin: 0 0 rem(71px);
	padding-bottom: vw(50px);
	position: relative;
}


.chooses-brands {
	display: grid;
	grid-template-columns: 1fr;
	margin-top: vw(50px);
	grid-column-gap: 10px;
	grid-row-gap: 10px;

	@include media(sm) {
		grid-column-gap: 20px;
		grid-row-gap: 20px;
		grid-template-columns: 1fr 1fr;
	}

	@include media(def) {
		grid-column-gap: 40px;
		grid-row-gap: 30px;
	}
}

.chooses-brand {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 30px 0 ;
	text-align: center;
	background-color: map-get($colors, salat-light);
	transition: background-color ease .4s;

	&:hover {
		background-color: map-get($colors, salat-dark);
		transition: background-color ease .4s;
	}

	@include media(def) {
		padding: 50px 0 40px;
	}

	&__city {
		font-size: 10px;
		color: map-get($colors, dark-lite);
		text-transform: uppercase;
		position: relative;
		width: max-content;
		margin: 0 auto;

		&::after,
		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 7px;
			height: 1px;
			background-color: map-get($colors, dark-lite);
			top: 50%;
			transform: translateY(-50%);
		}

		&::after {
			left: -14px;
		}

		&::before {
			right: -14px;
		}
	}

	&__name {
		font-size: 18px;
		line-height: rem(40px);
		text-transform: uppercase;
		max-width: 400px;
		margin: 0 auto;
		font-weight: 500;
		min-height: 80px;
		padding: 0 10px;
		color: map-get($colors, dark);
		letter-spacing: 0.2em;
		margin-bottom: vw(39px);
		overflow: hidden;

		@include media(sm) {
			font-size: rem(18px);
		}

		@include media(ms) {
			height: 96px;
			font-size: rem(22px);
		}

		@include media(def) {
			font-size: rem(22px);
			height: 116px;
		}

		@include media(lg) {
			font-size: rem(30px);
		}
	}

	&__adress {
		font-size: rem(18px);
		line-height: rem(30px);
		padding: 0 15px;
		min-height: 60px;
		color: map-get($colors, dark-lite);
		margin-bottom: vw(43px);
		margin-top: auto;
	}
}

._spacer30 {
	display: block;
	width: 100%;
	border: none;
	background-color: transparent;
	height: 30px;
	margin: 0;
}
