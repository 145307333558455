.uniq-block {
	position: relative;
	width: 100%;
	height: vw(912px);

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.3);
	}

	@include media(def) {
		width: vw(1279px);
		height: vw(612px);
	}

	@include media(dt) {
		width: 1279px;
		height: 612px;
	}

	&__text {
		position: absolute;
		z-index: 2;
		text-align: center;
		top: vw(200px);
		width: 100%;
		left: 50%;
		transform: translateX(-50%);
		font: {
			size: vw(70px);
			weight: 500;
		};
		text-transform: uppercase;
		letter-spacing: 0.2em;
		color: map-get($colors, light);


		@include media(sm) {
			font-size: vw(50px);
			width: auto;
		}

		@include media(def) {
			font-size: vw(30px);
		}

	}
}
