////
/// @group _blocks/
////

/// Description
.button {
	display: block;
	width: max-content;
	padding: 4px 21px;
	border: 1px solid map-get($colors, dark);
	background-color: map-get($colors, dark);
	color: map-get($colors, light);
	box-sizing: border-box;
	line-height: normal;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	text-transform: uppercase;
	z-index: 2;
	font-size: 12px;
	letter-spacing: 0.4em;
	margin: 0 auto;
	transition: color ease 0.4s, background-color ease 0.4s;

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
		top: 0;
		left: -100%;
		background-color: rgba(255, 255, 255, 0.3);
	}

	&:hover {

		&::after {
			left: 300%;
			transition: left ease 1.5s;
		}
	}

	@include media(sm) {
		font-size: 10px;
	}

	@include media(ms) {
		display: inline-block;
		font-size: rem(12px);
		margin: 0;
	}

	&--full-width {
		width: 100%;
		margin-right: 0;
	}

	&:only-child {
		margin: 0;
	}

	&:active {
		background-color: #333;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.6;
	}

	&.is-active {
		cursor: default;
		background-color: #333;
	}

	&--product-page {
	  margin-top: rem(50);

	  @include media(ms) {
		margin-top: rem(50);
	  }
	}

	&--absolute {
		position: absolute;
		left: 50%;
		top: 64%;
		transform: translate(-50%, -50%);

		@include media(sm) {
			top: 62%;
		}
	}

	&--brands {
		padding: 4px 14px;

		@include media(sm) {
			font-size: 10px;
		}

		@include media(def) {
			font-size: vw(12px);
		}
	}

	&--center {
		display: block;
		min-width: 145px;
		padding: 4px 16px;
		margin:  0 auto;
		font-size: 12px;
		text-align: center;

		@include media(sm) {
			font-size: 13px;
		}

		@include media(ms) {
			display: block;
			margin: 0 auto;
		}
	}

	&--abus {
		font-size: 12px;
		padding: 4px 17px;
		justify-self: end;

		@include media(sm) {
			font-size: 10px;
		}
	}

	&--search-result {
		padding: 4px 35px;
	}

	&--form {
		position: absolute;
		bottom: rem(20px);
		left: 50%;
		transform: translateX(-50%);
		font-size: 10px;
		padding: 4px 17px;
		justify-self: end;
		min-width: 123px;

		@include media(sm) {
			font-size: 12px;
			right: rem(20px);
			left: initial;
			transform: none;
		}
	}

	&--mini-form {
		margin: 25px auto 0;
		font-size: 10px;
		padding: 4px 17px;
		justify-self: end;

		@include media(sm) {
			font-size: 12px;
			position: absolute;
			min-width: 150px;
			top: 50%;
			transform: translateY(-50%);
			right: rem(20px);
			margin-top: 0;
		}
	}
}

.ripple-2 {
	.arrows-main &,
	.button &,
	.search-block__button & {
		position: absolute;
		width: 100% ;
		border-radius: 50%;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		opacity: 0;
		background-color: rgba(0, 0, 0, 0.7);
		transform: scale(0);
		animation-name: ripple;
		animation-timing-function: linear;
		animation-duration: 0.6s;
		animation-iteration-count: 1;
	}

	.button & {
		z-index: -1;
		animation-fill-mode: backwards;
		animation-direction: alternate;
	}

	.search-block__button & {
		background-color: rgba(127, 188, 83, 0.7);
	}
}

@keyframes ripple {

	to {
		transform: scale(2);
		opacity: 1;
	}
}
