.brands-item {
	display: grid;
	position: relative;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	margin-bottom: 13px;
	height: vw(1661px);
	transition: transform 0.7s ease-out;

	@include media(sm) {
		height: 65vw;
	}

	@include media(ms) {
		height: 67vw;
		margin-bottom: vw(100px);
	}

	@include media(md) {
		height: vw(850px);
	}

	@include media(def) {
		height: vw(580px);
	}

	&__bg {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		height: max-content;
		align-items: center;
		z-index: 1;
		max-width: 351px;
		text-align: right;
		text-transform: uppercase;
		margin: vw(95px) auto 0;

		@include media(ms) {
			align-items: flex-end;
			margin: vw(95px) vw(45px) 0 0;
		}

		@include media(lg) {
			margin: vw(95px) 0 0 vw(54px);
		}
	}

	&__name {
		font: {
			size: 20px;
			weight: 500;
		};
		line-height: 30px;
		letter-spacing: 0.22em;
		display: block;
		color: map-get($colors, dark);
		transition: color ease 0.7s;
		max-width: 207px;
		margin: 0 auto 4px;
		width: 100%;
		text-align: center;

		@include media(sm) {
			font-size: rem(30px);
			line-height: rem(39px);
			max-width: 270px;
		}

		@include media(ms) {
			font-size: 22px;
			line-height: 36px;
			text-align: right;
			margin: 0 0 6px;
		}

		@include media(def) {
			font-size: vw(30px);
			line-height: vw(44px);
		}

		@include media(lg) {
			max-width: 270px;
		}

		@include media(mgh) {
			max-width: 351px;
		}

		span {
			display: inline;
			background-color: white;
			padding: 0 10px 0 15px;
		}
	}

	&__manufacturer {
		font-size: 10px;
		color: #333;
		padding: rem(20px) 0 0;
		letter-spacing: 0.2em;

		@include media(ms) {
			padding: rem(40px) rem(22px) 0 0;
		}
	}

	&:hover {

		.brands-item {
			&__name {
				color: map-get($colors, light);
				transition: color ease 0.7s;

				span {
					background-color: black;
				}
			}
		}

		.chisel {
			width: 100%;
			background-color: rgba(0, 0, 0, 0.1);
			transition: background-color ease 0.7s, width ease 0.7s;
		}
	}

	&:nth-of-type(2n) {

		@include media(md) {
			transform: translateY(vw(120px));
		}

		.brands-item__content {
			display: block;
			text-align: center;
			margin: vw(95px) auto 0;

			@include media(ms) {
				margin: vw(95px) 0 0 vw(209px);
				text-align: left;
			}
		}

		.brands-item__name {

			@include media(ms) {
				text-align: left;
			}
		}

		.chisel {
			left: initial;
			right: 0;
		}

		.brands-item__manufacturer {
			padding: vw(40px) 0 0;
			text-align: center;

			@include media(ms) {
				padding: vw(40px) 0 0 vw(16px);
				text-align: left;
			}
		}
	}
}
