.arcticle-item {
	display: block;
	width: 100%;
	margin-bottom: 20px;

	@include media(ms) {
		display: grid;
		margin-bottom: 0;
	}

	@include media(def) {
		width: vw(1280px);
	}

	&__image {
		display: block;
		position: relative;
		width: vw(1651px);
		height: vw(1517px);
		overflow: hidden;
		margin: 0 auto;

		img {
			width: 100%;
			height: 100%;
		}

		@include media(sm) {
			width: vw(1286px);
			height: vw(1344px);
		}

		@include media(ms) {
			margin: 0;
			width: vw(929px);
			height: vw(876px);
		}

		@include media(def) {
			width: vw(640px);
			height: vw(580px);
		}

	}

	&__info {
		width: 100%;
		text-transform: uppercase;
		display: flex;
		position: relative;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 7px;
		padding: rem(50px) 0;
		background-color: #fff;
		grid-column-start: 2;
		height: vw(1517px);

		@include media(sm) {
			height: vw(1344px);
		}

		@include media(ms) {
			height: auto;
			padding: 0;
			background-color: #dde9ed;
			grid-column-start: 2;
		}

		@include media(def) {
			width: vw(640px);
			font-size: 10px;
		}

		&::before {
			content: '';
			position: absolute;
			width: vw(1651px);
			top: 0;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			background-color: #dde9ed;

			@include media(sm) {
				width: vw(1286px);
			}

			@include media(ms) {
				display: none;
			}
		}

	}

	&__content {
		width: 100%;
		text-align: center;
		position: relative;
		z-index: 1;

		@include media(ms) {
			transform: translateX(#{vw(-125px)});
		}

		@include media(def) {
			width: vw(679px);
			transform: translateX(#{vw(-25px)});
		}
	}

	&__name {
		letter-spacing: 0.4em;
	}

	&__date {
		letter-spacing: 0.2em;
		margin-top: 7px;
	}

	&__text {
		display: block;
		font: {
			size: 15px;
			weight: 500;
		};
		line-height: 18px;
		letter-spacing: 0.19em;
		padding: 0 10px;
		margin: 20px 0;
		color: map-get($colors, dark);

		@include media(ms) {
			font-size: vw(40px);
			line-height: vw(50px);
			margin: vw(37px) 0;
			padding: 0;
		}

		@include media(def) {
			font-size: vw(30px);
			line-height: vw(40px);
		}
	}
}
