.tabs-block {
	grid-template-columns: 0.7fr 1fr;
	justify-items: end;
	width: 100%;
	position: relative;
	grid-gap: 50px;
	display: block;
	margin-bottom: rem(50px);

	@include media(md) {
		display: grid;
		width: 99.8%;
		margin-bottom: -75px;
	}

	@include media(def) {
		width: 66.61458vw;
		grid-template-columns: 0fr 1fr;
		margin-bottom: -114px;
	}

	@include media(lg) {
		grid-template-columns: 1fr 1fr;
	}

	&__content {
		width: 100%;
		background-color: #f3f8fa;
		padding: 33px 15px 34px;
		transform: none;
		height: max-content;
		display: none;

		@include media(md) {
			width: vw(1000px);
			padding: 33px 15px 84px;
		}

		@include media(def) {
			width: vw(621px);
			transform: translateY(#{vw(-160px)});
			padding: vw(50px) vw(50px) vw(96px);
		}

		&.is-active {
			display: flex;
			flex-direction: column;
		}
	}

	&__numb {
		font-size: rem(75px);
		color: map-get($colors, green-dark);
		margin: -10px 0 vw(30px);

		@include media(md) {
			font-size: vw(100px);
		}

		@include media(lg) {
			margin: -28px 0 vw(30px);
		}
	}

	&__btn-group-1 {
		width: vw(665px);
		margin-top: vw(90px);
		display: none;

		@include media(md) {
			display: block;
		}

		@include media(def) {
			width: vw(550px);
		}

		@include media(lg) {
			max-width: 439px;
		}
	}

	&--catalog {

		@include media(md) {
			margin-bottom: 0;
			margin-top: 3px;
		}

		@include media(def) {
			margin-bottom: 0;
			margin-top: 0;
		}

		@include media(dth) {
			margin-bottom: -59px;
		}
	}
}

.tabs-main {
	position: relative;
}

.btn-group-1 {
	display: block;
	position: relative;
	background: none;
	padding: 0;
	text-align: left;
	border: none;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	font: {
		size: 12px;
		weight: 500;
	};
	cursor: pointer;
	line-height: 40px;
	color: map-get($colors, dark);
	margin-bottom: vw(45px);
	transition: padding ease 0.4s;


	@include media(def) {
		font-size: 13px;
		margin-bottom: vw(15px);
	}

	@include media(lg) {
		font-size: vw(18px);
		margin-bottom: vw(45px);
	}

	&.is-active {
		color: map-get($colors, green-dark);

		&::before {
			width: 13px;
			margin: 0 12px vw(5px) 0;
		}
	}

	&::before {
		content: '';
		display: inline-block;
		margin: 0 0 5px;
		width: 0;
		height: 2px;
		background-color: map-get($colors, green-dark);
		transition: width ease .4s, margin ease .4s;
	}

	&:hover {
		transition: padding ease 0.4s;
		color: map-get($colors, green-dark);

		&::before {
			width: 13px;
			margin: 0 12px vw(5px) 0;
			transition: width ease .4s, margin ease .4s;
		}
	}


	&--many {
		margin-bottom: vw(-12px);

		@include media(def) {
			font-size: 13px;
			margin-bottom: vw(-12px);
		}

		@include media(lg) {
			margin-bottom: 3px;
		}
	}

}

.btn-group-2 {
	@extend .arrows-main;
	z-index: 5;
	border: none;

	&--left {
		top: 0;

		@include media(md) {
			top: 0;
			right: vw(-3px);
		}

		@include media(def) {
			top: vw(-160px);
			right: 0;
		}
	}

	&--right {

		top: 50px;

		@include media(ms) {
			top: 80px;
		}

		@include media(md) {
			top: 79px;
			right: vw(-3px);
		}

		@include media(def) {
			top: 0;
			right: vw(-160px);
		}
	}
}
