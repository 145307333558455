////
/// @group _blocks/
////

/// Основная обертка страницы
.wrapper {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	position: relative;
	flex: 1 0 auto;
}
