/* generated */ $argv: (production: false, ftp: false);
////
/// @group style.scss
////

// сбор всех основных блоков для текущего проекта
// данный файл только импортирует нужные блоки кода
// написание чистого кода в этом файле - запрещается
// -------------------------------------------------Т

@import "_settings/all";

// SYSTEM & VENDORS
// -------------------------------------------------
@import "normalize.css/normalize";
@import "_system/html-tags";
@import "_system/print";
@import "_settings/functions/vw";
@import "_settings/functions/em-rem";

//HELPERS

@import "_settings/mixins/helpers-color";

// structure
@import "_blocks/wrapper";
@import "_blocks/section";
@import "_blocks/container";
@import "_blocks/breadcrumbs";

// ui
@import "_blocks/demo";
@import "_blocks/cookie";
@import "_blocks/ratio";
@import "_blocks/preloader";
@import "_blocks/sitemap";
@import "_blocks/wstabs";
@import "_blocks/scroll-up";
@import "_blocks/title";
@import "_blocks/select2";
@import "_blocks/search-result";
@import "_blocks/where-buy";
@import "_blocks/left-menu";
@import "_blocks/suposed-block";
@import "_blocks/brands";
@import "_blocks/contacts";
@import "_blocks/articles";
@import "_blocks/watch-movie";
@import "_blocks/statiiAkcii";
@import "_blocks/learn-page";
@import "_blocks/tovar-item";
@import "_blocks/form-bottom-block";
@import "_blocks/uniq";
@import "_blocks/akcii-main";
@import "_blocks/tabs-block";
@import "_blocks/bg-dots";
@import "_blocks/brands-item";
@import "_blocks/brands-logos";
@import "_blocks/akcii-item";
@import "_blocks/search-block";
@import "_blocks/m-menu";
@import "_blocks/articles-item";
@import "_blocks/articles-wearth";
@import "_blocks/bg-block";
@import "_blocks/header";
@import "_blocks/learning";
@import "_blocks/info-main";
@import "_blocks/footer";
@import "_blocks/slider-main";
@import "_blocks/pagination";
@import "_blocks/google-map";
@import "_blocks/draggable-table";

// popup
@import "_blocks/popup";

// form
@import "_blocks/form";
@import "_blocks/button";


@import "_blocks/map";

.calendar-wrap {
  @include media(def) {
	padding: 0 0 0 vw(160px) ;
  }

  @include media(mg) {
	padding: 0 vw(50px) 0 0;
  }
}

.language {
	display: block;
	color: #000;
	margin-bottom: 10px;

	&--mob {
		margin: 20px 15px 15px 0;
		font-size: 16px;
		position: absolute;
		top: 10px;
		right: 86px;
		z-index: 5;

		@media(max-width: 640px) {
			top: 0;
			right: 65px;
		}

		@media(max-width: 375px) {
			top: -4px;
			right: 53px;
		}
	}

	&:hover {
		color: #8dbf69;
	}

	span {
		margin-bottom: 10px;

		&:last-child {
			display: block;
			margin-bottom: 5px;
		}
	}
}

