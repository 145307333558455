.fixed-menu {
	position: fixed;
	overflow-y: scroll;
	top: -300%;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	z-index: 5;
	transition: top ease 0.6s;

	&__link {
		display: block;
		color: map-get($colors, dark);
		text-transform: uppercase;
		font-size: 16px;
		padding: 7px 17px;
	}

	&__social-media {
		margin: 20px 0 15px 15px;
	}

	&__bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__items {
		padding: 21px 0 4px;
		text-align: center;
	}

	&__item {
		border-bottom: 1px solid map-get($colors, dark);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;

		svg {
			width: 12px;
			height: 12px;
			transform: rotate(180deg);
			margin: 0 0 0 -6px;
		}

		ul {
			width: 100%;
		}
	}

	&.is-open {
		top: 0;
		transition: top ease 0.6s;

		& + .fixed-button {
			i {
				background-color: transparent;

				&::before {
					top: 0;
					bottom: 0;
					transform: rotate(45deg);
					transition: transform ease 0.7s;
					background: #fff;
				}

				&::after {
					top: 0;
					transform: rotate(-45deg);
					transition: transform ease 0.7s;
					background: #fff;
				}
			}
		}
	}
}

.fixed-button {
	display: flex;
	width: 50px;
	height: 50px;
	z-index: 99;
	cursor: pointer;
	position: fixed;
  	background-color: #000;
	top: 0;
	right: 0;

	@include media(xs) {
		width: 60px;
		height: 60px;
	}

  	@include media(ms) {
	  width: 80px;
	  height: 80px;
  	}

	@include media(def) {
		display: none;
	}

	i {
		display: block;
		background-color: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 50%;
		z-index: 1;
		height: 3px;

		&::before,
		&::after {
			content: '';
			position: absolute;
			background-color: #fff;
			width: 100%;
			height: 100%;
			left: 0;
			transition: transform ease 0.7s;
		}

		&::before {
			bottom: 300%;
			transform: rotate(0deg);
			transition: bottom ease .7s, transform ease 0.7s;
		}

		&::after {
			top: 300%;
			transform: rotate(0deg);
			transition: top ease .7s, transform ease 0.7s;
		}
	}
}
