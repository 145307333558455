.header {
	position: relative;
}

.cg-description {
	width: 100%;
	position: relative;
	margin: 0 auto vw(268px);
	z-index: 3;
	display: grid;
	justify-content: end;
	background-color: map-get($colors, light);
	padding: vw(120px) 15px 0 0;


	@include media(ms) {
		width: 71%;
		transform: translate(#{vw(51px)}, #{vw(-200px)});
		padding: vw(40px) vw(160px) 0 0;
		margin: 0 auto vw(-150px);
	}

	@include media(def) {
		width: 59%;
		transform: translate(#{vw(71px)}, #{vw(-200px)});
	}

	@include media(mg) {
		margin: 0 auto vw(-110px);
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 50px;
		height: 100%;
		background-color: map-get($colors, blue_light);
		display: none;

		@include media(xs) {
			width: 60px;
		}

		@include media(ms) {
			width: vw(160px);
			display: block;
		}
	}
}

.right-palsh {
	position: absolute;
	top: vw(-290px);
	right: vw(29px);
	width: 50px;
	height: vw(850px);
	background-color: map-get($colors, blue_light);
	display: none;

	@include media(def) {
		display: block;
	}

	@include media(xs) {
		width: 60px;
	}

	@include media(ms) {
		width: vw(160px);
	}

	@include media(dth) {
		right: vw(28px);
	}
}
