.brands-logos {
	width: 100%;
	max-width: 1293px;
	margin-bottom: vw(135px);
	max-height: 72px;
	overflow: hidden;

	@include media(def) {
		width: 101%;
	}

	&--brandpage {
		margin-top: 49px;

		@include media(md) {
			margin-top: vw(100px);
		}

	}

	&--page {
		max-width: 1300px;
		margin-bottom: vw(35px);
	}

	&__item {
		max-width: 100%;
		display: block;
		opacity: 0.5;
		transition: opacity ease 0.7s;

		&:hover {
			opacity: 1;
			transition: opacity ease 0.7s;
		}
	}

	&--about {
		max-width: 1292px;
		margin-bottom: vw(100px);

		@include media(md) {
			margin-bottom: vw(40px);
		}
	}
}
