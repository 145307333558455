////
/// @group _blocks/
////

/// Description
.google-map {
	position: relative;
	width: 100%;
	margin: 0 auto;

	&__container {
		position: relative;
		padding-top: 100%;
		background: map-get($colors-network, google-map-bg) no-repeat center;

		&:empty {
			background-image: url(./static/pic/preloader.svg);

			.ie & {
				background-image: url(./static/pic/preloader.gif);
			}
		}

		@include media(ms) {
			padding-top: 61.8%;
		}

		@include media(def) {
			padding-top: 43.8%;
		}
	}
}

.google-map-point {
	border: 1px solid #ccc;
	border-bottom-width: 0;
	padding: 1rem;
	cursor: pointer;

	&:last-child {
		border-bottom-width: 1px;
	}

	&:hover {
		background-color: #fafafa;
	}

	&.is-active {
		background-color: #eee;
	}
}

.google-map-infowindow {
	font-weight: normal;
	max-width: 300px;
	padding: 5px 0;
}

.map-info-item {
	display: block;
	color: map-get($colors, green-dark);
}

.map-phone {
	margin: 9px 0 6px;
}
