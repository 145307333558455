.suposed-block {
	display: flex;
	justify-content: center;
	align-items: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	height: vw(980px);
	margin-bottom: vw(100px);
	width: 95%;
	margin: 0 auto vw(100px);

	@include media(sm) {
		height: vw(680px);
	}

	@include media(def) {
		width: 100%;
		height: vw(380px);
	}

	&__title {
		font: {
			size: rem(23px);
			weight: 600;
		};
		text-transform: uppercase;
		line-height: rem(25px);
		letter-spacing: 0.2em;
		text-align: center;
		margin-bottom: rem(42px);

		@include media(sm) {
			font-size: rem(24px);
			line-height: rem(30px);
		}

		@include media(def) {
			font-size: rem(30px);
			line-height: rem(36px);
		}
	}
}
