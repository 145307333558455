////
/// @group _blocks/
////

/// Пользовательские заголовки

.title-main {
	font: {
		size: rem(30px);
		weight: 500;
	};
	color: map-get($colors, dark);
	text-transform: uppercase;
	line-height: rem(60px);
	letter-spacing: 0.4em;
	margin-right: -0.4em;
	text-align: center;
	margin-bottom: vw(60px);

	@include media(lg) {
		font-size: rem(34px);
		margin-right: 0.4em;
	}

	&--center {
		text-align: center;
		margin-bottom: 20px;
		position: relative;

		@include media(lg) {
			margin-right: 0;
		}
	}

	&--middle {
		letter-spacing: 0.2em;
		margin-bottom: vw(34px);

		@include media(lg) {
			font-size: rem(30px);
			line-height: rem(50px);
			margin-right: 0;
		}
	}

	&--left {
		text-align: center;

		@include media(ms) {
			text-align: left;
			margin-right: 0;
		}
	}

	&--innerbl {
		text-align: center;
		margin-right: 0;
		padding-left: vw(50px);
		line-height: 29px;
		height: 30px;
		margin-bottom: vw(100px);

		@include media(def) {
			text-align: left;
		}

	}

	&--innerbl-2 {
		padding-left: 0;

		margin-bottom: vw(900px);

		@include media(xs) {
			margin-bottom: vw(1002px);
		}

		@include media(sm) {
			margin-bottom: vw(1021px);
		}

		@include media(ms) {
			margin-bottom: vw(73px);
		}

		@include media(ms) {
			text-align: left;
			height: auto;
			line-height: initial;
		}

		@include media(lg) {
			font-size: vw(40px);
		}
	}

	&__big {
		font-size: rem(31px);
		text-transform: none;
		letter-spacing: 0.05em;
		margin-bottom: vw(160px);
		margin-right: 0;

		span {
			color: map-get($colors, green_focus);
		}

		@include media(xs) {
			font-size: rem(34px);
		}

		@include media(sm) {
			font-size: vw(90px);
		}

		@include media(lg) {
			font-size: vw(90px);
			margin-right: 0;
		}

		&--mb {
			margin-bottom: vw(83px);
		}
	}

	&--bigger {
		max-width: 813px;
		font: {
			size: rem(40px);
			weight: 400;
		}
		margin-right: 0;
		letter-spacing: 0.05em;
		line-height: rem(50px);
		text-transform: none;
		text-align: left;
		margin-bottom: rem(25px);

		@include media(ms) {
			font-size: vw(98px);
			line-height: vw(80px);
			margin-bottom: 55px;
		}

		@include media(lg) {
			font-size: vw(100px);
			line-height: vw(80px);
			margin-bottom: 55px;
		}
	}

	&--bigger-width {
		max-width: 980px;
	}

	&--text-block {
		letter-spacing: 0.2em;
		line-height: rem(40px);

		@include media(ms) {
			margin-bottom: rem(-11px);
		}

		@include media(lg) {
			line-height: rem(60px);
		}

	}
}

.tab-title {
	max-width: 430px;
	letter-spacing: 0.2em;
	text-transform: uppercase;
	font: {
		size: 16px;
		weight: 500;
	};
	line-height: 22px;
	margin-bottom: 33px;

	@include media(md) {
		padding-right: 53px;
	}

	@include media(def) {
		font-size: vw(30px);
		line-height: vw(40px);
		padding-right: 0;
	}

	&--mini {
		font-size: 8px;
		color: #333;
		max-width: 100%;
		margin-bottom: 0;
		padding-right: 25px;

		@include media(md) {
			padding-right: 45px;
		}

		@include media(def) {
			font-size: vw(10px);
		}
	}
}
