////
/// @group _blocks/
////

/// Description
.preloader {
	position: relative;

	&--show {
		pointer-events: none;
	}

	&__block {
		position: absolute;
		top: -2px;
		left: -2px;
		right: -2px;
		bottom: -2px;
		z-index: 10;

		&::before {
			$t: map-get($transitions, fast);
			$bg: map-get($colors, light);
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba($bg, .8) url(./static/pic/preloader.svg) no-repeat center;
			opacity: 0;
			transition: $t;

			.ie & {
				background-image: url(./static/pic/preloader.gif);
			}

			.button & {
				background-size: auto 70%;
			}

			.preloader--show & {
				opacity: 1;
			}
		}
	}
}
