.main-page-arcticle {

	.arcticle-item {

		.chisel {
			background-color: rgba(255, 255, 255, 0.04);
			display: none;

			&::after {
				content: '';
				width: vw(160px);
				z-index: 1;
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				background-color: rgba(255, 255, 255, 0.5);
				transition: background-color ease 0.7s, width ease 0.7s, transform ease 0.5s, left ease 0.5s;
			}

			&--white {
				background-color: rgba(255, 255, 255, 0.04);
				transition: none;
			}

			@include media(ms) {
				display: block;
			}
		}

		&:hover {
			.chisel {
				width: 100%;
				transition: width ease 1s;
				background-color: rgba(255, 255, 255, 0.5);

				&--white {
					transition: background-color ease 0.5s, width ease 1s;
					background-color: rgba(255, 255, 255, 0.5);
				}

				&::after {
					left: -100%;
					transition: width ease 1s;
				}
			}

			&:nth-child(odd) {

				.chisel {
					width: 100%;
					background-color: rgba(255, 255, 255, 0.5);

					&--white {
						background-color: rgba(255, 255, 255, 0.5);
						transition: width ease 1s;
					}

					&::after {
						right: -100%;
						left: initial;
						transition: width ease 1s;
					}
				}
			}
		}

		&:nth-child(odd) {

			.chisel {
				right: 0;
				left: initial;
				display: none;

				&::after {
					content: '';
					width: vw(160px);
					z-index: 1;
					position: absolute;
					right: 0;
					left: initial;
					top: 0;
					bottom: 0;
					background-color: rgba(255, 255, 255, 0.5);
					transition: background-color ease 0.7s, width ease 0.7s, transform ease 0.5s, right ease 0.5s;
				}

				&--white {
					background-color: rgba(255, 255, 255, 0.04);
					transition: none;
				}

				@include media(ms) {
					display: block;
				}
			}
		}

		&:nth-child(2n) {
			.arcticle-item {
				&__image {
					grid-column-start: 2;
				}

				&__info {
					grid-column-start: 1;
					grid-row-start: 1;
					background-color: #fff;

					&::before {
						content: '';
						position: absolute;
						width: vw(1651px);
						top: 0;
						bottom: 0;
						left: 50%;
						transform: translateX(-50%);
						background-color: #fcdace;

						@include media(sm) {
							width: vw(1286px);
						}

					}

					@include media(ms) {
						background-color: #fcdace;
					}
				}

				&__content {

					@include media(ms) {
						transform: translateX(#{vw(100px)});
					}

					@include media(def) {
						transform: translateX(#{vw(50px)});
					}
				}
			}
		}
	}
}
