.map-block {
	width: 100%;
	position: relative;
	max-width: 1600px;
	margin: 50px 0 0;
	max-height: 700px;
	grid-row: 1 / 1;
	grid-column: 1 / 4;
	justify-self: end;

	@include media(ms) {
		margin-top: 0;
	}
}
