.learning-block {
	display: block;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 50px;
	padding: 0 13px;
	width: 100%;
	margin-bottom: vw(88px);

	&::after {
		content: '';
		display: block;
		clear: both;
	}

	@include media(md) {
		display: grid;
	}

	@include media(def) {
		width: vw(1330px);
		grid-column-gap: 100px;
		padding: 0;
		grid-template-columns: 1fr minmax(320px, 530px);
	}

	&__image {
		position: relative;
		width: vw(1728px);
		height: vw(1728px);
		margin: 0 auto rem(52px);

		@include media(md) {
			width: vw(950px);
			height: vw(950px);
		}

		@include media(def) {
			width: vw(640px);
			height: vw(640px);
			margin: 0;
		}
	}
}
