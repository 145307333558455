 ////
/// @group _blocks/
////

/// Description
.scroll-up {
	position: absolute;
	z-index: 5;
	bottom: 20px;
	opacity: 1;
	height: 0;
	transition: height ease 0.7s;
	background: none;
	border: none;
	cursor: pointer;
	text-transform: uppercase;

	@include media(mgh) {
		height: 0;

		&.on-scroll {
			height: 69%;
			transition: height ease 0.7s;
		}
	}

	@include media(def) {
		position: fixed;
		bottom: 299px;
		right: vw(74px);
		left: initial;
		transform: none;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: 50%;
		transform: translateX(50%);
		width: 40px;
		height: 102%;

	}

	>span {
		display: none;
	}

	.center-line {
		background-color: #848a8e;
		display: none;

		span {
			position: absolute;
			left: 50%;
			transform: translateX(-50%) rotate(90deg);
			display: block;
			width: max-content;
			bottom: -62px;
			font-size: 12px;
		}
	}

	@include media(def) {

		.center-line {
			display: block;
			top: initial;
			height: 0;
			opacity: 0;
			transition: height ease 0.5s, opacity ease 3s;
			bottom: 75px;

			span {
				display: none;
			}

			&.on-scroll {
				height: 100%;
				transition: height ease 3s, opacity ease 3s;
				opacity: 1;

				span {
					display: block;
				}
			}
		}
	}

	&.scroll--md {
		display: none;
		top: initial;
		bottom: 30px;
		width: 45px;
		height: 45px;
		border-radius: 5px;
		align-items: center;
		justify-content: center;
		position: fixed;
		right: 0;
		transform: translateX(-50%);

		&.is-active {
			background-color: map-get($colors, dark);
			color: #fff;
			z-index: 4;

			&::after {
				display: none;
			}
		}

		&.on-scroll {
			display: flex;
		}
	}
}
