.contact-content {
	width: 100%;
	display: block;
	position: relative;
	z-index: 2;
	margin-bottom: 36px;

	@include media(md) {
		display: grid;
		grid-template-columns: 0 vw(872px);
		margin-bottom: 0;
	}

	@include media(def) {
		grid-template-columns: vw(377px) vw(284px);
		padding-left: vw(291px);
	}

	@include media(xl) {
		grid-template-columns: vw(703px) vw(111px);
	}
}

.contact-adress {
	max-width: 763px;
	margin: 0;
	font: {
		size: rem(12px);
		weight: 500;
	};
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	color: #000;
	line-height: rem(32px);
	margin-bottom: 28px;

	@include media(ms) {
		font-size: rem(12px);
		line-height: rem(32px);
		text-align: left;
	}

	@include media(md) {
		font-size: rem(22px);
		line-height: rem(32px);
	}

	@include media(def) {
		font-size: vw(22px);
		line-height: vw(32px);
	}

	@include media(lg) {
		font-size: vw(30px);
		line-height: vw(40px);
	}

	&__adress {
		display: block;
	}

	&__link {
		color: map-get($colors, dark);
	}

	&__social-media {
		padding-bottom: rem(20);
	}
}

.form-contact-block {
	width: 100%;
	padding: vw(82px) vw(80px) vw(50px);
	z-index: 1;
	background-color: map-get($colors, light);
	grid-row: 1 / 1;
	grid-column: 3 / 3;
	margin-right: vw(160px);
	max-width: 640px;
	margin-top: 26px;

	&--vakancii {
		padding-left: 0;
	}

	@include media(md) {
		width: auto;
		transform: translateY(#{vw(-70px)});
		margin-top: 0;
	}

	&__title {
		margin: 0;
		font: {
			size: rem(22px);
			weight: 500;
		};
		text-transform: uppercase;
		letter-spacing: 0.2em;
		color: #000;
		line-height: rem(32px);
		margin-bottom: 11px;

		@include media(ms) {
			margin-bottom: 11px;
		}


		@include media(def) {
			font-size: rem(22px);
			line-height: rem(32px);
			margin-bottom: 28px;
		}

		@include media(lg) {
			font-size: vw(30px);
			line-height: vw(50px);
		}
	}

	.form-group--textarea {
		margin-top: 20px;

		@include media(ms) {
			margin-top: 0;
		}
	}

	textarea {
		min-height: 180px;

		@include media(ms) {
			min-height: 140px;
		}

		@include media(def) {
			min-height: 180px;
		}
	}

	.form-group {

		@include media(ms) {
			margin-top: 15px;
		}

		@include media(def) {
			margin-top: 20px;
		}

		@include media(xl) {
			margin-top: 30px;
		}
	}

	.form-bottom-block__m-title {


		@include media(ms) {
			margin-bottom: 16px;
		}


		@include media(def) {
			margin-bottom: 9px;
		}
	}
}
