.info-main {
	display: grid;
	grid-template-columns: 0 95%;
	margin-bottom: vw(75px);

	@include media(ms) {
		grid-template-columns: vw(720px) 23%;
	}

	@include media(def) {
		grid-template-columns: vw(479px) vw(467px) vw(333px);
	}

	@include media(dt) {
		grid-template-columns: 479px 467px 333px;
	}

	&--product {
	  margin-bottom: 0;
	}

	&--contacts {
		margin-bottom: 0;
	}

	&__decription {
		grid-column-start: 1;
		max-width: 567px;
		grid-column-end: 3;
		z-index: 4;
		grid-row-start: 1;
		padding-left: 13px;
		margin-top: 46px;
		position: relative;

		@include media(def) {
			margin-top: vw(201px);
			padding-left: 0;
		}

		&--contacts {
			max-width: 763px;
		}
	}

	&__image {
		position: relative;
		grid-row-start: 1;
		grid-column-start: 2;
		width: vw(1920px);
		height: vw(1690px);
		overflow: hidden;

		@include media(ms) {
			width: vw(1200px);
			height: vw(1000px);
		}

		@include media(def) {
			width: vw(802px);
			height: vw(700px);
		}

		img {
			height: 100%;
			width: 101%;
		}
	}

	.gallery-btn {

		&:nth-of-type(1) {
			z-index: 2;
		}
	}

	&--catalog {
		margin-bottom: 0;
	}
}

.gallery-btn {
	border: none;
	background-color: transparent;
	cursor: pointer;
	padding: 0;
	margin: 0;
	left: 0;
	top: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
}
