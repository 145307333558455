////
/// @group _blocks/
////

/// Description
.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-family: 'proxima_novabold', "Helvetica Neue", Helvetica, sans-serif;
	margin: -10px 0 vw(74px);

	@include media(sm) {
		margin: vw(164px) 0 vw(74px);
	}

	&__orders {
		margin: 26px 0 -1px;

		@include media(sm) {
			margin: 66px 0 0;
		}

		.pagination {

			&__arrow {
				display: none;
			}
		}
	}

	&__number {
		font-size: 19px;
		min-width: 36px;
		min-height: 36px;
		border: 1px solid transparent;
		text-decoration: none;
		border-radius: 100%;
		display: grid;
		align-items: center;
		color: map-get($colors, dark);
		order: 2;
		margin: 6px;

		@include media(sm) {
			font-size: 24px;
			min-width: 44px;
			min-height: 44px;
		}

		@include media(md) {
			margin: 6px;
		}

		@include media(def) {
			margin: 11px;
		}

		&[href] {
			color: map-get($colors, default);

			&:hover {
				color: map-get($colors, green-dark);
				background-color: transparent;
				border: 1px solid map-get($colors, green-dark);
			}

			&:active {
				color: map-get($colors, white);
				background-color: map-get($colors, green-dark);
			}

			&:focus {
				background-color: map-get($colors, light);
			}
		}

		&--current {
			color: map-get($colors, green-dark);
			background-color: transparent;
			border: 1px solid map-get($colors, green-dark);
		}
	}

	&__arrow {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		border: 1px solid #000;
		border-radius: 100%;

		svg {
			width: 7px;
			fill: map-get($colors, dark);
			transition: ease fill 0.7s;
		}

		&:hover {
			color: map-get($colors, green-dark);
			background-color: transparent;
			border: 1px solid map-get($colors, green-dark);

			svg {
				width: 7px;
				fill: map-get($colors, green-dark);
				transition: ease fill 0.7s;
			}

		}

		&:active {
			color: map-get($colors-link, green-dark);
		}

		&:not([href]) {
			color: inherit;
			pointer-events: none;
			opacity: .3;
		}

		&--prev {
			order: 1;
			margin-right: vw(49px);
		}

		&--next {
			order: 3;
			margin-left: vw(49px);
		}
	}
}
