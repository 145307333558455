////
/// @group _blocks/
////

/// Блок для центрирования контента
.container {
	width: 100%;
	position: relative;
	flex-basis: 100%;
	max-width: 1582px;
	padding-left: rem(15px);
	padding-right: rem(15px);
	margin-left: auto;
	margin-right: auto;

	& &,
	&--no-gap {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	&--full {
		max-width: 100%;
	}

	@include clearfix();

	&--mg {
		display: flex;
		width: 100%;
		justify-content: flex-start;
		padding: 0;


		@include media(def) {
			width: 83.4%;
			justify-content: flex-end;
		}
	}

	&--mgh {
		display: flex;
		width: 100%;
		padding: 0;
		flex-direction: column;

		@include media(def) {
			width: 68%;
		}
	}

	&--center {
		width: 100%;
		margin: 0 auto;

		@include media(def) {
			width: 69.5%;
		}
	}

	&--mgd {
		display: flex;
		width: 98%;
		padding: 0;
		flex-direction: column;

		@include media(ms) {
			width: 100%;
		}

		@include media(xl) {
			width: 68%;
		}
	}

	&--mgb {
		padding-left: 0;

		@include media(def) {
			padding-left: vw(300px);
		}
	}

	&--map {
		max-width: 100%;
		padding: 0;
	}
}
