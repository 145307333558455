.footer {
	font-size: rem(13px);
	padding: rem(46px) 0 rem(75px);
	letter-spacing: 0.2em;
	line-height: rem(26px);
	color: #333;
	display: flex;
	width: 100%;
	justify-content: flex-end;
	flex: 0 0 auto;

	@include media(md) {
		font-size: rem(10px);
		padding: rem(86px) 0 rem(100px);
	}

	@include media(def) {
		font-size: rem(10px);
		line-height: rem(26px);
	}

	@include media(xl) {
		font-size: rem(12px);
		line-height: rem(30px);
	}

	&__content {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr;
		justify-content: space-between;
		padding: 0 17px;
		text-align: center;

		@include media(md) {
			grid-template-columns: repeat(3, 30%);
			grid-column-gap: 40px;
			text-align: left;
		}

		@include media(def) {
			width: 90%;
			grid-template-columns: minmax(246px, 24.21875vw) minmax(310px, 35%) minmax(232px, 1%);
			padding: 0;
			grid-column-gap: 25px;
		}

		@include media(xl) {
			width: vw(1280px);
			grid-template-columns: minmax(302px, #{vw(465px)}) minmax(437px, 1fr) minmax(236px, 1%);
		}
	}

	&__link {
		display: inline-block;
		color: #333;
		position: relative;

		&:hover {
			color: map-get($colors, green);
		}

		&.underline {

			&::after {
				content: '';
				position: absolute;
				bottom: 3px;
				width: 100%;
				left: 0;
				height: 2px;
				background-color: map-get($colors, dark);
				transition: width ease 0.7s;
			}

			&:hover {
				color: #333;

				&::after {
					width: 50%;
					transition: width ease 0.7s;
				}
			}
		}
	}

	&__info {
		max-width: 490px;
		text-align: center;
		margin: 27px auto 26px;

		@include media(md) {
			margin: 0;
		}

		@include media(def) {
			text-align: left;
		}

		br {
			display: block;

			@include media(ms) {
				display: none;
			}
		}
	}

	&__dev {
		justify-self: center;

		@include media(md) {
			justify-self: end;
		}

		li {

			&:last-child {

				@include media(md) {
					text-align: right;
				}
			}
		}
	}
}
