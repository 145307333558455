////
/// @group _partials/sitemap.scss
////


// vars
// ================================

/// @type Color
$sitemap-hoverbg: rgba(#000, .035) !default;

// scss
// ================================

// общие правила

.site-map-content {
	width: 100%;
	margin: vw(200px) 0 vw(150px) vw(300px);
}

.sitemap {
	max-width: 1024px;
	position: relative;
	font-size: 1rem;
	margin-bottom: 1.5rem;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	li {
		list-style: none;
		line-height: 1.4em;
	}

	a {
		display: block;
	}
}

.sitemap > ul {

	& > li > a {
		font-size: 1.2rem;
		font-weight: 500;
		color: #141130;
		transition: color 0.5s ease;
		border-bottom: 1px solid #999;
		min-height: 40px;
		padding: 8px 0;
		display: flex;
		align-items: center;
		text-decoration: none;
		cursor: pointer;
		position: relative;

		&::after {
			position: absolute;
			left: 0;
			bottom: -2px;
			content: "";
			height: 2px;
			width: 100%;
			transform: rotateY(86deg);
			transform-origin: left;
			transition: background-color 0.5s ease;
			background-color: #333;
		}

		&:hover {
			color: #2400ff;

			&::after {
				background-color: #333;
			}
		}
	}
}

// второй уровень
// без паддингов
.sitemap > ul > li > ul {
	padding-left: 0;
}

.sitemap > ul > li > ul > li > ul > li > ul > li > a {
	color: #141130;
}

// все остальные уровни
// sass-lint:disable-all
.sitemap > ul ul {
	list-style: none;
	padding: 0 0 0 2em;
	margin: 0;

	a {
		font-size: 1rem;
		color: #2d3b4e;
		font-weight: 500;
		transition: color .5s ease, border-color 0.5s ease;
		border-bottom: 1px solid #e1dbcd;
		min-height: 30px;
		padding: 8px 0 8px 20px;
		display: flex;
		align-items: center;
		text-decoration: none;
		cursor: pointer;
		position: relative;

		&:hover {
			color: #141130;
			border-color: #333;
		}
	}
}

.sitemap > ul ul li {
	position: relative;
	margin: .1em 0;
}

.map-site-link {
    text-transform: uppercase;
    margin-left: 11px;
}
// sass-lint:enable-all
