@import "_settings/functions/vw";
@import "_settings/functions/em-rem";

html {
	position: relative;
	height: 100%;
	font-family: map-get($font-family, main);
	color: map-get($colors, text);
	line-height: 1.6;
	font-size: 12px;

	@include media(sm) {
		font-size: 13px;
	}

	@include media(md) {
		font-size: 14px;
	}

	@include media(def) {
		font-size: 15px;
	}

	@include media(lg) {
		font-size: 16px;
	}
}

*, // sass-lint:disable-line no-universal-selectors
::after,
::before {
	box-sizing: inherit;
}

body {
	position: relative;
	height: 100%;
	box-sizing: border-box;
	min-width: 320px;
	background-color: #fff;
}

select {
	display: none;
}

span {
	&.button {
		background-color: #fff;
		color: #000;
	}
}

.page-not-found {
	width: 100%;
	padding-left: vw(320px);
	margin-top: vw(150px);

	&__err {
		display: block;
		font-size: vw(70px);
		margin-bottom: vw(40px);
	}
}

.top-bl {

	@include media(def) {
		padding-left: vw(160px);
	}

	&--akcii {

		@include media(def) {
			padding-left: 0;
		}
	}
}

.selo-previewer {
	z-index: 512521521;

	&__list {
		position: fixed;
		overflow-y: scroll;
		left: 0;
		top: 0;
		height: 100%;
		width: 340px;
		padding: 0 2%;
		z-index: 512521521;
		display: flex;
		flex-direction: column;
		background-color: #464342;
		align-items: center;
		justify-content: center;
		transform: translateX(-101%);
		transition: transform 0.2s ease-out;

		&.is-active {
			transform: translateX(0);
		}
	}

	&__item {
		font-weight: bold;
		color: #fff;
		width: 100%;
		margin: 0 auto;
		font-size: 15px;
		text-align: center;
		padding: 12px 0;
		display: block;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		border-bottom: 1px solid #fff;
		transition: background-color ease 0.7s, transform ease 0.7s;

		&:hover {
			background-color: #000;
			transition: background-color ease 0.7s, transform ease 0.7s;
			transform: scale(0.9);
		}
	}

	&__open {
		position: fixed;
		left: 10px;
		bottom: 10px;
		width: 80px;
		height: 30px;
		color: #fff;
		background-color: red;
		cursor: pointer;
		font-weight: bold;
		font-size: 18px;
		z-index: 512521521;
	}

	&__close {
		position: absolute;
		top: 10px;
		right: 10px;
		background-color: blue;
		font-weight: bold;
		color: #fff;
		font-size: 18px;
		cursor: pointer;
	}
}

.center-tinka {
	padding-left: 15px;
	margin: vw(50px) 0 0;
	max-width: 920px;

	@include media(def) {
		margin-top: vw(123px);
	}

	@include media(def) {
		padding-left: 8.3vw;
	}

	&--brand-page {
		margin-top: vw(115px);
	}
}

.preloader-2 {
	position: relative;
	width: 180px;
	margin: 0 auto;

	&__text {
		color: #7fbc53;
		font-size: 12px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		&--right {
			right: 0;
		}
	}

}

.loading {
	width: 36px;
	height: 36px;
	margin: 0 auto;
	border-radius: 100%;
	position: relative;
	animation-name: rotate;
	animation-iteration-count: infinite;
	animation-fill-mode: forwads;
	animation-duration: 4s;
	animation-timing-function: linear;

	&__palochki {
		position: absolute;
		width: 2px;
		height: 13px;
		background-color: #7fbc53;
		transform: translateZ(1px) rotate(0deg);
		will-change: transform;


		&:nth-child(2n + 1) {
			animation-name: preloader;
			animation-fill-mode: forwads;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
			animation-duration: 1s;

		}

		&:nth-child(3n) {
			animation-name: preloader-2;
			animation-fill-mode: both;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
			animation-duration: 1s;
		}

		&:nth-child(1) {
			top: -13px;
			left: 50%;
			transform: translateX(-50%);
		}

		&:nth-child(2) {
			top: -7px;
			left: 99%;
			transform: translateX(-50%) rotate(45deg);
		}

		&:nth-child(3) {
			top: 50%;
			right: -13px;
			width: 13px;
			height: 2px;
			transform: translateY(-50%);
		}

		&:nth-child(4) {
			bottom: -7px;
			left: 99%;
			transform: translateX(-50%) rotate(-45deg);
		}

		&:nth-child(5) {
			bottom: -13px;
			left: 50%;
			transform: translateX(-50%);
		}

		&:nth-child(6) {
			bottom: -7px;
			left: 0;
			transform: translateX(-50%) rotate(45deg);
		}

		&:nth-child(7) {
			top: 50%;
			left: -13px;
			width: 13px;
			height: 2px;
			transform: translateY(-50%);
		}

		&:nth-child(8) {
			top: -7px;
			left: 0;
			transform: translateX(-50%) rotate(-45deg);
		}
	}
}

@keyframes rotate {

	0% {
		transform: rotate(0deg);
	}

	50% {
		transform: rotate(180deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes preloader {

	0% {
		background-color: rgba(127, 188, 83, 0.25);
	}

	25% {
		background-color: rgba(127, 188, 83, 0.5);
	}

	50% {
		background-color: rgba(127, 188, 83, 0.75);
	}

	100% {
		background-color: rgba(127, 188, 83, 1);
	}
}

@keyframes preloader-2 {

	100% {
		background-color: rgba(127, 188, 83, 0.25);
	}

	75% {
		background-color: rgba(127, 188, 83, 0.5);
	}

	50% {
		background-color: rgba(127, 188, 83, 0.75);
	}

	25% {
		background-color: rgba(127, 188, 83, 1);
	}
}

.chisel {
	width: vw(160px);
	z-index: 1;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.04);
	transition: background-color ease 5s, transform ease 0.5s, width ease 0.5s;

	&__text {
		transform: translate(-50%, -50%) rotate(-90deg);
		position: absolute;
		font: {
			size: vw(30px);
			weight: 500;
		};
		line-height: vw(50px);
		left: 50%;
		top: 50%;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		color: #92c66d;
	}

	&--white {
		background-color: rgba(255, 255, 255, 0.5);
	}

	&--right {
		left: initial;
		right: 0;
	}

	&--ms {
		width: 100%;
		background-color: rgba(255, 255, 255, 0.75);
		z-index: 3;

		@include media(ms) {
			width: vw(420px);
			background-color: rgba(255, 255, 255, 0.65);
		}

		@include media(def) {
			width: vw(160px);
			background-color: rgba(255, 255, 255, 0.5);
		}
	}
}

.center-line {
	position: absolute;
	width: 1px;
	background-color: #b2b2b2;
	top: 0;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	display: none;

	@include media(lg) {
		display: block;
	}

	&::after {
		content: '';
		position: absolute;
		bottom: -20px;
		width: 4px;
		height: 4px;
		background-color: map-get($colors, dark);
		left: 50%;
		transform: translateX(-50%) rotate(45deg);
	}

	&--rightbl {
		height: vw(458px);
		background-color: #848a8e;

		span {
			font-size: rem(12px);
			text-transform: uppercase;
			letter-spacing: 0.2em;
			position: absolute;
			bottom: rem(-96px);
			width: max-content;
			left: 0;
			transform: translateX(-50%) rotate(90deg);
		}
	}
}

ul {
	padding: 0;
	margin: 0;

	li {
		position: relative;
		list-style: none;
	}
}

a {
	text-decoration: none;
	transition: color ease .4s;

	&:hover {
		transition: color ease .4s;
	}
}

pre {
	padding: 1.2em;
	tab-size: 4;
	border-width: 0;
	white-space: pre-wrap;
	word-wrap: break-word;
	font-family: map-get($font-family, monospace);
}

img {
	max-width: 100%;
	height: auto;
}

small {
	display: inline-block;
	vertical-align: text-bottom;
}

svg {
	button &,
	a & {
		pointer-events: none;
	}
}

audio {
	&[controls] {
		display: block;
		width: 100%;
	}
}

video,
iframe {
	max-width: 100%;
}

hr {
	border-width: 0;
	border-top: 1px solid;
}

::selection {
	text-shadow: none;
	color: #fff;
	background: #2597ff;
}

input,
button,
select,
textarea {
	font-family: map-get($font-family, main);
}

[type="reset"],
[type="submit"],
button,
html [type="button"] {
	-webkit-appearance: none;
}

button:focus,
div:focus {
	outline: none;
}

.main-slider {

	.swiper-container {
		max-height: 707px;
		overflow: hidden;
	}
}

.arrow-top {
	display: block;

	@include media(def) {
		display: none;
	}

	svg {
		fill: #fff;
	}
}

// sass-lint:disable-all

.mfp-close {
	cursor: pointer !important;
}

.calendar-block {
	margin-bottom: 35px;
	width: 100%;
	max-width: 1280px;
	height: 76vw;

	@include media(ms) {
		height: 52vw;
	}

	@include media(def) {
		height: 32vw;
		margin-bottom: 75px;
	}

	@include media(mgh) {
		height: 550px;
	}
}

.mCSB_scrollTools {
	opacity: 1!important;
}

.mCSB_scrollTools .mCSB_draggerContainer {
    bottom: 20px!important;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: pointer!important;
}