.bg-dots {

	&--main {
		.dots:nth-child(1) {
			top: 6.3%;
			right: 0;
		}

		.dots:nth-child(2) {
			top: 35%;
			left: 0;
		}
	}

	&--second {

		.dots:nth-child(1) {
			top: 21%;
			right: 0;
		}
	}

	&--about {
		.dots:nth-child(1) {
			top: 0;
			right: 0;
		}

		.dots:nth-child(2) {
			top: 50%;
			left: 0;
		}
	}

	&--footer {

		.dots:nth-child(1) {
			top: -131%;
			left: 0;
		}
	}

	&--brands {

		.dots:nth-child(1) {
			top: 23.5%;
			left: 0;
		}

		.dots:nth-child(2) {
			top: 34.7%;
			right: 0;
		}

		.dots:nth-child(3) {
			top: 45.9%;
			left: 0;
		}

		.dots:nth-child(4) {
			top: 57.7%;
			right: 0;
		}

		.dots:nth-child(5) {
			top: 68.3%;
			left: 0;
		}
	}

	&--contacts {
		.dots:nth-child(1) {
			top: 79.5%;
			left: 0;
		}

		.dots:nth-child(2) {
			top: 166.7%;
			right: 0;
		}
	}

	&--akcii {
		.dots:nth-child(1) {
			top: 39%;
			right: 0;
		}

		.dots:nth-child(2) {
			top: 52%;
			left: 0;
		}

		.dots:nth-child(3) {
			top: 79%;
			right: 0;
		}
	}

	&--articles {
		.dots:nth-child(1) {
			top: 35%;
			right: 0;
		}

		.dots:nth-child(2) {
			top: 55%;
			left: 0;
		}

		.dots:nth-child(3) {
			top: 74%;
			right: 0;
		}
	}

	&--learn {
		.dots:nth-child(1) {
			top: 28.6%;
			right: 0;
		}

		.dots:nth-child(2) {
			top: 42.3%;
			left: 0;
		}

		.dots:nth-child(3) {
			top: 56.6%;
			right: 0;
		}
	}

	&--spisok {
		.dots:nth-child(1) {
			top: -35%;
			right: 0;
		}

		.dots:nth-child(2) {
			top: -16.7%;
			left: 0;
		}
	}

	&--tovar {
		.dots:nth-child(1) {
			top: -4%;
			right: 0;
		}
	}


	&--search-result {
		.dots:nth-child(1) {
			top: 29.6%;
			right: 0;
		}

		.dots:nth-child(2) {
			top: 42.3%;
			left: 0;
		}

		.dots:nth-child(3) {
			top: 61.6%;
			right: 0;
		}
	}

	&--text {
		.dots:nth-child(1) {
			top: 9.5%;
			right: 0;
		}
	}
}

.dots {
	position: absolute;
	width: vw(290px);
	height: vw(404px);
	display: none;

	@include media(ms) {
		display: block;
	}
}

.no-image {
	width: 100%;
	height: 100%;
	background: url(../../assets/images/no-image.png) no-repeat center;
	background-size: cover;
}
