.search-block {
	position: absolute;
	right: 50px;
	top: -50px;
	width: 84%;

	@include media(sm) {
		width: 82.5%;
	}

	@include media(xs) {
		right: 60px;
		top: -60px;
	}

	@include media(ms) {
		right: 80px;
		top: -80px;
	}

	@include media(def) {
		width: vw(1440px);
		right: 0;
		top: 0;
	}

	&__pic {
		position: absolute;
		top: 0;
		z-index: 4;
		right: 0;
		display: none;

		img {
			width: 50px;
			height: vw(1000px);

			@include media(xs) {
				width: 60px;
			}

			@include media(sm) {
				height: vw(950px);
			}

			@include media(ms) {
				width: 80px;
			}

			@include media(def) {
				width: vw(160px);
				height: vw(700px);
			}
		}

		@include media(def) {
			display: block;
		}
	}

	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		background-color: map-get($colors, dark);
		cursor: pointer;
		border: none;
		position: relative;
		overflow: hidden;
		border-right: 1px solid #fff;
		transition: background-color ease 0.5s;

		@include media(xs) {
			width: 60px;
			height: 60px;
		}

		@include media(ms) {
			width: 80px;
			height: 80px;
		}

		@include media(def) {
			border: none;
			width: vw(160px);
			height: vw(160px);
		}

		svg {
			width: 20px;
			height: 20px;
			position: relative;
			z-index: 3;
			fill:  map-get($colors, light);

			@include media(def) {
				width: vw(30px);
				height: vw(30px);
			}

		}

		&:hover {
			background-color: map-get($colors, green_focus);
			transition: background-color ease 0.8s;
		}

		&.is-active {
			background-color: map-get($colors, green_focus);
		}
	}
}

.close-search {
	position: absolute;
	cursor: pointer;
	top: 50%;
	transform: translateY(-50%);
	right: vw(70px);
	background: none;
	border: none;
	height: 13px;

	@include media(ms) {
		height: auto;
	}

	@include media(def) {
		height: vw(34px);
	}

	svg {
		fill: #4d4d4d;
		width: 13px;
		height: 13px;
		transition: fill ease 0.7s;

		@include media(ms) {
			width: vw(34px);
			height: vw(34px);
		}
	}

	&:hover {
		svg {
			fill: map-get($colors, green-dark);
			transition: fill ease 0.7s;
		}
	}

	&::after {
		content: '';
		position: absolute;
		transform: translate(-50%, -50%);
		left: 50%;
		top: 50%;
		width: 220%;
		height: 220%;
		z-index: 2;
	}
}

.btn-group {
	z-index: 5;
}

.btn-submit {
	display: none;
}
