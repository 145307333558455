.form-bottom-block {
	width: 100%;
	margin-bottom: vw(100px);

	@include media(def) {
		padding-left: vw(320px);
	}

	@include media(xl) {
		padding-left: vw(160px);
	}

	&__m-title {
		font-size: 10px;
		line-height: 15px;
		color: #333;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		margin-bottom: rem(30px);

		@include media(ms) {
			margin-bottom: 37px;
			line-height: 30px;
		}

	}

	&--tovar-page {
		margin-bottom: 0;

		@include media(def) {
			padding-left: vw(480px);
		}
	}


	&--padleft {
		padding-left: 0;
		padding-right: 15px;

		@include media(def) {
			padding: 0;
		}

		@include media(xl) {
			padding: 0;
		}
	}
}
