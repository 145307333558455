.form {
	position: relative;
	text-align: center;
	max-width: 960px;

	&--search {
		display: flex;
		justify-content: space-between;
		position: absolute;
		background-color: map-get($colors, blue_light);
		top: 50px;
		left: 0;
		max-width: 100%;
		width: 100%;
		margin: 0;
		height: auto;

		@include media(xs) {
			top: 60px;
		}

		@include media(ms) {
			top: 80px;
		}

		@include media(def) {
			top: 0;
		}

		.form-group {

			&__input,
			&__textarea,
			&__select {

				~ label {
					&.has-error {
						top: initial;
						bottom: 6%;
						right: 2%;
						font-size: 16px;
						background-color: transparent;
					}
				}
			}
		}
	}
}

.form-group {
	position: relative;
	margin-top: 20px;
	width: 100%;

	@include media(ms) {
		margin-top: 30px;
	}

	&:first-child {
		margin-top: 0;
	}

	&__label {
		&[for] {
			cursor: pointer;
		}
	}

	&--textarea {
		margin-top: 0;
	}

	&__caption {
		position: absolute;
		left: 18px;
		top: 50%;
		transform: translateY(-50%);
		color: map-get($colors, dark);
		cursor: text;
		font-size: 10px;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		transition: top ease .5s;
		background-color: map-get($colors, light);
		width: auto;
		padding: 0;
		text-align: center;
		pointer-events: none;

		&--textarea {
			top: 20px;
		}

		&--search {
			text-transform: uppercase;
			left: vw(67px);
			font-size: 12px;
			letter-spacing: 0.2em;
			color: #fff;
			background-color: transparent;
			opacity: 1;
			transition: opacity 0.5s;

			@include media(ms) {
				font-size: vw(40px);
			}
		}
	}

	&__input,
	&__textarea,
	&__select {
		border: 1px solid map-get($colors, dark);
		display: block;
		width: 100%;
		font-size: 16px;
		padding: 0 18px;
		color: map-get($colors, dark);
		transition: border ease 0.5s;
		height: 40px;

		@include media(ms) {
			height: 49px;
		}

		&.has-error {
			// sass-lint:disable-all
			border: 1px solid  map-get($colors, error) !important;
			color: map-get($colors, error);
			// sass-lint:enable-all
			background-color: rgba(map-get($colors, light), 0.9);

			~ .select2-container--focus,

			&:focus {
				~ label {
					&.has-error {
						visibility: visible;
						pointer-events: auto;
					}
				}
			}
		}

		~ label {
			&.has-error {
				position: absolute;
				width: 100%;
				top: 100%;
				right: 0;
				z-index: 10;
				line-height: 1.2;
				text-transform: lowercase;
				text-align: right;
				font-size: 11px;
				letter-spacing: 0.025em;
				border-radius: 0 0 5px 5px;
				color: map-get($colors, error);
				visibility: hidden;
				pointer-events: none;

				@include media(ms) {
					font-size: 14px;
				}
			}
		}

		&:focus {
			outline: none;
		}
	}

	&__textarea {
		resize: none;
		min-height: 207px;
		padding-top: 20px;

		@include media(def) {
			min-height: 187px;
		}

		@include media(xl) {
			min-height: 207px;
		}

		&--tovarpage {
			min-height: 207px;

			@include media(def) {
				min-height: 277px;
			}

			@include media(def) {
				min-height: 267px;
			}

			@include media(xl) {
				min-height: 285px;
			}
		}

		&::placeholder {
			color: #999;
			opacity: 1;
		}
	}

	&.is-focused {
		.form-group {
			&__caption {
				top: 2%;
				background-color: #fff;
				transition: top ease 0.5s;
				padding: 0 10px;
				left: 8px;
				color: rgba(51, 51, 51, 0.6);

				&--textarea {
					top: 2px;
				}


				&--search {
					top: 50%;
					left: vw(67px);
					transition: opacity ease 0.5s;
					opacity: 0;
					padding: 0;
					background-color: transparent;
				}
			}
		}
	}

	&--search {
		width: 100%;
		z-index: 4;
		opacity: 0;
		height: 50px;
		transform: scaleX(0);
		transform-origin: right;
		transition: opacity ease 0.5s, transform ease 0.5s;


		@include media(xs) {
			height: 60px;
		}

		@include media(ms) {
			height: 80px;
		}

		@include media(def) {
			height: vw(160px);
		}

		.form-group {

			height: 100%;

			&__input {
				background-color: map-get($colors, dark);
				color: map-get($colors, light);
				font-size: 12px;
				padding: 0 vw(140px) 0 vw(67px);
				letter-spacing: 0.2em;
				height: 100%;
				text-transform: uppercase;
				border-radius: 0;

				@include media(ms) {
					font-size: vw(40px);
				}
			}
		}

		&.is-active {
			transform: scaleX(1);
			transform-origin: right;
			opacity: 1;
			height: 50px;
			transition: opacity ease 0.5s, transform ease 0.5s;


			@include media(xs) {
				height: 60px;
			}

			@include media(ms) {
				height: 80px;
			}

			@include media(def) {
				height: auto;
			}

			&+ .btn-group {

				.btn-submit {
					display: block;
					background-color: #7fbc53;
				}

				.btn-show  {
					display: none;
				}
			}

		}

	}

}

.form-check {
	display: inline-block;
	vertical-align: top;
	margin: 0 3px 5px 0;

	&:only-child {
		margin: 0;
	}

	span {
		display: inline-block;
		vertical-align: top;

		&::before {
			content: '';
			display: inline-block;
			vertical-align: sub;
			margin-right: 4px;
			width: 1.2em;
			height: 1.2em;
			border: 1px solid;
		}
	}

	input {
		position: absolute;
		width: 0;
		height: 0;
		line-height: 0;
		visibility: hidden;
		z-index: -1;

		&.has-error ~ span {
			color: map-get($colors, error);
		}

		&:checked ~ span {
			color: map-get($colors, main);

			&::before {
				background-color: map-get($colors, main);
				border-color: map-get($colors, main);
				box-shadow: inset 0 0 0 4px map-get($colors, light);
			}
		}

		&[type="radio"] {
			~ span {
				&::before {
					border-radius: 50%;
				}
			}
		}
	}
}


.form-parts {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 20px;

	@include media(ms) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 40px;
	}
}

.form-part {
	position: relative;

	&--first {
		margin-bottom: 25px;
	}
}

// sass-lint:disable-all

.form--search input:-webkit-autofill,
.form--search input:-webkit-autofill:hover,
.form--search input:-webkit-autofill:focus,
.form--search input:-webkit-autofill:active {
	-webkit-text-fill-color: #fff;
}

.has-error input:-webkit-autofill,
.has-error input:-webkit-autofill:hover,
.has-error input:-webkit-autofill:focus,
.has-error input:-webkit-autofill:active,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
	transition: background-color ease-in-out 5000000s;
}
