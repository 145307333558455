.logo {
	display: block;
	margin-bottom: vw(84px);
	width: 283px;
	transition: width ease 0.2s;
	overflow: hidden;

  //@include media(def) {
	//width: 241px;
	//max-width: 241px;
  //}
  //
  //@include media(mg) {
	//width: 283px;
	//max-width: 283px;
  //}

	img {
	  width: 283px;
	  max-width: 283px;

	  //@include media(def) {
		//width: 241px;
		//max-width: 241px;
	  //}
	  //
	  //@include media(mg) {
		//width: 283px;
		//max-width: 283px;
	  //}

		@include media(mg) {
			max-width: 283px;
		}
	}

	&.on-scroll {
		width: 104px;
		transition: width ease 0.7s;

	  //@include media(mg) {
		//width: 104px;
	  //}
	}

	&--fmenu {
		display: block;
		width: 220px;
		margin: 33px 0 15px 17px;

		@include media(sm) {
			width: 283px;
		}

		img {
			width: 100%;
			max-width: 100%;
		}
	}

}

.left-block {
	position: fixed;
	top: vw(88px);
	left: vw(68px);
	z-index: 3;
	padding-left: 15px;
	display: none;
	transition: left ease 0.7s;
	height: calc(100vh - #{vw(192px)});


	&.on-scroll {
		left: 5px;
		transition: left ease 0.7s;
	}

	@media(max-width: 1199px) {
		left: 26px;
	}

	&.bgc {
		background-color: rgba(255, 255, 255, 0.8);
	}

	@include media(def) {
		display: block;

	}

	&__menu-list {
		max-height: calc(100% - #{vw(260px)});
		margin: 0 0 vw(36px) -12px;

		@include media(900px, max, height) {
			max-height: calc(79% - #{vw(150px)});
		}

		&.scroll-hide {
			// sass-lint:disable-all

			.mCSB_scrollTools {
				opacity: 0;
			}

			// sass-lint:enable-all
		}
	}

	&__menu-item {
		font-size:  calc(9px + .3vw);
		line-height: calc(13px + .3vw);
		text-transform: uppercase;
		font-weight: 500;
		letter-spacing: 0.2rem;
		margin-bottom: 13px;
		overflow: hidden;
		transition: padding ease .4s;

		&.is-active {

			.left-block__menu-link {
				padding-left: 24px;
				transition: padding ease 0.4s;
				color: map-get($colors, dark);

				&::before {
					width: 13px;
					transition: width ease .4s;
				}
			}
		}

		&--brand {
			cursor: pointer;

			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 7px;
				width: 0;
				height: 2px;
				background-color: map-get($colors, dark);
				transition: width ease .4s;
			}

			&:hover {
				padding-left: 24px;
				transition: padding ease 0.4s;
				color: map-get($colors, dark);

				&::before {
					width: 13px;
					transition: width ease .4s;
				}
			}

			>a {
				pointer-events: none;
			}

			&.is-active {
				padding-left: 24px;
				transition: padding ease 0.4s;
				color: map-get($colors, dark);

				&::before {
					width: 13px;
					transition: width ease .4s;
				}
			}
		}
	}

	&__menu-link {
		color: map-get($colors, dark-lite);
		position: relative;
		display: block;
		padding-left: 0;
		transition: padding ease 0.4s;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 0;
			height: 2px;
			background-color: map-get($colors, dark);
			transition: width ease .4s;
		}

		&:hover {
			padding-left: 24px;
			transition: padding ease 0.4s;
			color: map-get($colors, dark);

			&::before {
				width: 13px;
				transition: width ease .4s;
			}
		}
	}

	&__soc-items {
		max-width: 140px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 12px;

		&.on-scroll {
			max-width: 100px;
		}
	}

	&__soc-item {
		transition: opacity ease .4s;

		&--link {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 38px;
			height: 38px;
			background-color: map-get($colors, dark);
			border: 1px solid map-get($colors, dark);
			border-radius: 100%;
			transition: width ease 0.7s, height ease 0.7s;

			svg {
				fill:  map-get($colors, light);
				width: 100%;
				height: 100%;
			}

			&.on-scroll {
				width: 30px;
				height: 30px;
				transition: width ease 0.7s, height ease 0.7s;
			}
		}

		&.is-hovered {
			transition: opacity ease .4s;
			opacity: 1;
		}

		&.is-unhovered {
			transition: opacity ease .4s;
			opacity: 0.15;
		}
	}
}

.svg-style {
	svg {
		width: 61%;
		height: 57%;
	}
}

.tels-list {
	margin-bottom: 31px;

	@include media(def) {
		margin-bottom: vw(50px);
	}

	&__item {
		font-size: 12px;
		line-height: 18px;
		font-weight: 500;
		margin-bottom: 6px;

		@include media(def) {
			font-size: vw(18px);
			line-height: vw(24px);
		}

		&--link {
			color: map-get($colors, dark);
			letter-spacing: 0.025em;

			&:hover {
				color: map-get($colors, green);
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__left-menu {
		margin-bottom: vw(50px);
	}
}

.gr {
	position: absolute;
	z-index: 2;
	left: 303px;
	max-width: 1440px;
}

.drop-menu {
	padding: 18px 0 1px;
	display: none;

	&__item {

		&.is-active {
			.drop-menu__link {
				color: map-get($colors, green-dark);
			}
		}
	}

	&__link {
		line-height: calc(15px + .7vw);
		font-size: calc(10px + 0.15vw);
		font-weight: 400;
		color: map-get($colors, dark);
		text-transform: uppercase;
		letter-spacing: 0.2em;

		&--m-menu {
			line-height: 30px;
			font-size: 13px;
		}

		&:hover {
			color: map-get($colors, green-dark);
		}
	}

	&__m-menu {
		padding: 3px 0 10px;
	}
}
