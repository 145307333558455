.akcii-main {
	display: flex;
	justify-content: flex-start;
	width: auto;
	margin-top: 145px;
	flex-direction: column;
	padding-left: 15px;

	@include media(ms) {
		width: 81%;
		margin-top: vw(250px);
	}

	@include media(def) {
		padding-left: 0;
		padding-right: 15px;
	}


	@include media(mg) {
		padding-right: 0;
	}
}

.akcii-content {

	&__select {
		max-width: 960px;
		width: 100%;
	}

	&__items {
		display: grid;
		grid-gap: 40px;
		margin-top: rem(50px);

		@include media(def) {
			grid-gap: 80px;
		}
	}
}

.akcii-slider {
	margin-top: rem(45px);
	padding: 0 5%;
}
