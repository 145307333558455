.articles {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	margin-top: 145px;
	flex-direction: column;
	padding-left: 15px;
	padding-right: 15px;

	@include media(ms) {
		width: 100%;
		margin-top: vw(201px);
	}

	@include media(md) {
		padding-right: 0;
	}

	@include media(def) {
		padding-left: 0;
		width: 91%;
	}


	&__list {
		margin-bottom: vw(80px);

		.arcticle-item {
			margin-bottom: 15px;

			@include media(ms) {
				margin-bottom: 0;
			}

			.chisel {
				background-color: rgba(255, 255, 255, 0.04);
				display: none;

				&::after {
					content: '';
					width: vw(160px);
					z-index: 1;
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					background-color: rgba(255, 255, 255, 0.5);
					transition: background-color ease 0.7s, width ease 0.7s, transform ease 0.5s, left ease 0.5s;
				}

				&--white {
					background-color: rgba(255, 255, 255, 0.04);
					transition: none;
				}

				@include media(ms) {
					display: block;
				}
			}


			&__info {
				background-color: #fff;
				transition: background-color ease 0.7s;
				position: relative;

				@include media(ms) {
					background-color: #fef4f0;
				}

				&::before {
					background-color: #fef4f0;
				}
			}

			&:nth-child(4n) {

				.arcticle-item {
					&__info {

						&::after {
							position: absolute;
							right: 0;
							top: 0;
							bottom: 0;
							width: vw(160px);
							background-color: map-get($colors, light);

							@include media(ms) {
								content: '';
							}
						}
					}
				}
			}

			&:nth-child(even) {

				.chisel {
					right: 0;
					left: initial;

					&::after {
						content: '';
						width: vw(160px);
						z-index: 1;
						position: absolute;
						right: 0;
						left: initial;
						top: 0;
						bottom: 0;
						background-color: rgba(255, 255, 255, 0.5);
						transition: background-color ease 0.7s, width ease 0.7s, transform ease 0.5s, right ease 0.5s;
					}

					&--white {
						background-color: rgba(255, 255, 255, 0.04);
						transition: none;
					}
				}
			}

			&:nth-child(odd) {
				.arcticle-item {

					&__image {
						grid-column-start: 2;
					}

					&__info {
						grid-column-start: 1;
						grid-row-start: 1;

						&::after {
							position: absolute;
							left: 0;
							top: 0;
							bottom: 0;
							width: vw(248px);
							background-color: map-get($colors, light);

							@include media(ms) {
								content: '';
								width: vw(160px);
							}
						}
					}

					&__content {

						@include media(ms) {
							transform: translateX(#{vw(100px)});
						}

						@include media(def) {
							transform: translateX(#{vw(50px)});
						}
					}
				}
			}

			&:hover {

				.chisel {
					width: 100%;
					transition: width ease 1s;
					background-color: rgba(255, 255, 255, 0.5);

					&--white {
						transition: background-color ease 0.5s, width ease 1s;
						background-color: rgba(255, 255, 255, 0.5);
					}

					&::after {
						left: -100%;
						transition: width ease 1s;
					}
				}


				&:nth-child(even) {

					.chisel {
						width: 100%;
						background-color: rgba(255, 255, 255, 0.5);

						&--white {
							background-color: rgba(255, 255, 255, 0.5);
							transition: width ease 1s;
						}

						&::after {
							right: -100%;
							left: initial;
							transition: width ease 1s;
						}
					}
				}

				.arcticle-item {

					&__info {
						background-color: #fcdace;
						transition: background-color ease 0.7s;
					}
				}
			}

		}
	}
}
