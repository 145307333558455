////
/// @group _settings/
////

// Брейкпоинты ширины или высоты экрана
// ---------------------------------

/// Карта брейкпоинтов в пикселях
/// @type Map
$breakpoints: (
	st: 320px,
	xs: 375px,
	psw: 412px,
	sm: 480px,
	ms: 640px,
	psh: 732px,
	md: 768px,
	def: 1024px,
	lg: 1280px,
	xl: 1366px,
	mg: 1440px,
	mgh: 1649px,
	dth: 1918px,
	dt: 1980px
);
