.search-result {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	margin-top: 145px;
	flex-direction: column;
	padding-left: 15px;
	padding-right: 15px;

	@include media(ms) {
		width: 100%;
		margin-top: vw(250px);
	}

	@include media(def) {
		width: 91%;
		padding-left: 0;
	}

	&__list {
		max-width: 1280px;
	}

	&__item {
		position: relative;
		background-color: #dad9d0;
		transition: background-color ease 0.7s;
		text-align: center;
		padding: rem(50px) vw(50px);
		margin-bottom: rem(35px);
		cursor: default;

		&:hover {
			background-color: #c2c0b1;
			transition: background-color ease 0.7s;
		}
	}

	&__category {
		font-size: rem(10px);
		letter-spacing: 0.2em;
		text-transform: uppercase;
		line-height: rem(24px);
		margin-bottom: rem(35px);
	}

	&__name {
		max-width: 860px;
		margin: 0 auto;
		font: {
			size: rem(22px);
			weight: 500;
		};
		text-transform: uppercase;
		letter-spacing: 0.2em;
		color: #000;
		line-height: rem(32px);
		margin-bottom: rem(41px);

		@include media(def) {
			font-size: vw(22px);
			line-height: vw(32px);
		}

		@include media(lg) {
			font-size: vw(30px);
			line-height: vw(40px);
		}
	}
}

.find-results {
	font-size: rem(12px);
	line-height: 30px;
	letter-spacing: 0.4em;
	text-transform: uppercase;
	margin: rem(-20px) 0 vw(91px);
	text-align: center;

	&__col {
		color: map-get($colors, green-dark);
	}

	@include media(ms) {
		font-size: rem(14px);
		text-align: left;
		margin: rem(-20px) 0 vw(91px);
	}
}
