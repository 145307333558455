.main-slider {
	position: relative;
	width: 100%;

	@include media(def) {
		width: 91%;
	}


	&__image {
		width: 100.1%;

		img {
			height: vw(1192px);
			width: 100%;

			@include media(sm) {
				height: vw(1000px);
			}

			@include media(def) {
				height: vw(700px);
			}
		}
	}

	&__description {
		display: block;
		font-size: 17px;
		line-height: 21px;
		text-align: left;
		left: 50px;
		background-color: rgba(0, 0, 0, 0);
		padding: 9px 17px;
		max-width: 300px;
		position: absolute;
		bottom: 6px;
		color: map-get($colors, light);
		text-transform: uppercase;
		letter-spacing: 0.05em;

		@include media(ms) {
			display: block;
			font-size: 18px;
			line-height: 21px;
			text-align: left;
			left: vw(298px);
			right: initial;
			background-color: rgba(0, 0, 0, 0);
			max-width: 409px;
			padding: 9px 17px;
			bottom: vw(247px);
		}

		@include media(def) {
			font: {
				size: vw(30px);
			};
			line-height: vw(36px);
			max-width: 609px;
			right: vw(319px);
			left: initial;
			text-align: right;
			background-color: transparent;
			padding: 0;
		}
	}
}

.slide-arrows {
	position: absolute;
	width: 160px;
	height: 100px;
	z-index: 3;
	top: 50%;
	transform: translateY(-50%);
	left: 0;

	@include media(ms) {
		height: 160px;
	}

	@include media(def) {
		top: 0;
		right: 0;
		transform: none;
		left: initial;
		height: 100%;
	}
}

.arrows-main {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	right: 0;
	overflow: hidden;
	background-color: map-get($colors, light);
	transition: background-color ease 0.5s;
	cursor: pointer;
	position: absolute;

	@include media(ms) {
		width: 80px;
		height: 80px;
	}

	@include media(def) {
		width: vw(160px);
		height: vw(160px);
	}

	svg {
		fill: map-get($colors, dark);
		width: 10px;
		height: 18px;
		position: relative;
		z-index: 5;

		@include media(def) {
			width: vw(17px);
			height: vw(33px);
		}
	}

	&:hover {
		background-color: map-get($colors, dark);
		transition: background-color ease 0.8s;

		svg {
			fill: map-get($colors, light);
			transition: fill ease 0.5s;
		}
	}

	&--left {
		top: 0;
		left: 0;

		@include media(def) {
			top: vw(190px);
			right: vw(158px);
			left: initial;
		}
	}

	&--right {
		bottom: 0;
		left: 0;

		@include media(def) {
			left: initial;
			bottom: vw(200px);
		}
	}
}

.js-main-slider {

	&::after {
		content: '';
		position: absolute;
		left: 0;
		z-index: 2;
		top: 0;
		width: 50px;
		bottom: 0;
		background-color: rgba(255, 255, 255, 0.6);

		@include media(ms) {
			width: 80px;
		}

		@include media(def) {
			width: vw(160px);
		}

	}
}

// sass-lint:disable-all

.js-logos-slider {

	.swiper-slide {
		margin-right: 0;
		display: flex;
		justify-content: center;
	}


	.swiper-wrapper {
		align-items: center;
	}
}

.js-main-slider .swiper-slide {
	width: 100% !important;
}
