.statii-akcii-main {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	margin-top: 50px;
	flex-direction: column;
	padding-left: 15px;

	@include media(ms) {
		width: 100%;
		margin-top: vw(50px);
	}

	@include media(def) {
		padding-left: 0;
		width: 81%;
	}
}

.add-this-block {
	font-size: rem(14px);
	line-height: rem(40px);
	letter-spacing: 0.4em;
	color: map-get($colors, green-dark);
	text-transform: uppercase;

	p {
		margin: 0 0 15px;
	}
}

// sass-lint:disable-all

.addthis_inline_share_toolbox {
	margin-left: vw(-46px);
	margin-bottom: vw(109px);
}
