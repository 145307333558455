////
/// @group _blocks/
////

/// Description

.breadcrumbs {
	position: relative;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	line-height: rem(22);
	list-style: none;
	margin: vw(346px) auto 0;
	font-size: rem(10px);
	letter-spacing: 0.2em;
	max-width: 455px;
	justify-content: center;
	text-transform: uppercase;
	margin-bottom: vw(75px);

	@include media(xs) {
		margin: vw(423px) auto 0;
	}

	@include media(sm) {
		margin: vw(499px) auto 0;
	}

	@include media(ms) {
		margin: 5em 0 0;
		justify-content: initial;
	}

	span {
		color: map-get($colors, green-dark);
	}

	> * { // sass-lint:disable-line no-universal-selectors
		margin: 0 .5em .5em 0;


		@include media(md, max) {
			&:not(:last-child) {
				&:not(:nth-last-child(2)) {
					display: none;
				}
			}

			&:nth-last-child(2)::after {
				content: '/';
				margin-left: .5em;
			}
		}

		@include media(md) {
			&:not(:last-child)::after {
				content: '/';
				margin: 0 11px;
				color: #333;
			}
		}
}

	a {
		&[href] {
			color: #333;

			&:hover {
				color: map-get($colors, green-dark);
			}

			&:active {
				color: map-get($colors, green-dark);
			}
		}
	}
}

.top-bl {
	margin-top: -46px;

	@include media(ms) {
		margin-top: 0;
	}

	.breadcrumbs {
		margin: 0 auto;

		@include media(xs) {
			margin: 0 auto;
		}

		@include media(sm) {
			margin: 0 auto;
		}

		@include media(ms) {
			margin: 1em 0 0;
		}
	}

}
