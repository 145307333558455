.brands-wearth {
	position: relative;
	margin-bottom: rem(70px);
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include media(ms) {
		margin-bottom: vw(147px);
	}
}

.brands-block {
	max-width: 1280px;
	display: grid;
	grid-template-columns: 1fr;
	justify-content: space-between;
	color: map-get($colors, light);
	position: relative;
	grid-column-gap: vw(100px);
	padding-bottom: vw(109px);
	margin-bottom: rem(70px);

	@include media(md) {
		grid-template-columns: 1fr 1fr;
	}
}

.brands-main {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	margin-top: 145px;
	flex-direction: column;
	padding-left: 15px;
	padding-right: 15px;

	@include media(ms) {
		margin-top: vw(201px);
	}

	@include media(md) {
		padding-right: 0;
	}

	@include media(def) {
		padding-left: 0;
	}
}

.brands-list {
	display: grid;
	grid-row-gap: 25px;
}

.brands-list-item {
	max-width: 1240px;
	display: grid;
	grid-template-columns: 1fr;
	justify-content: center;
	grid-gap: 0;

	@include media(sm) {
		grid-template-columns: 0.7fr;
	}
	
	@include media(ms) {
		grid-template-columns: 0.8fr;
	}

	@include media(md) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 30px;
	}

	@include media(def) {
		grid-gap: 100px;
	    margin-left: 8.33333vw;
	}

	&__text {
		margin-top: 0;

		@include media(md) {
			margin-top: vw(108px);
		}
	}

	&:nth-child(even) {
		.brands-item {
			grid-column-start: 1;

			@include media(md) {
				grid-column-start: 2;
			}
		}

		.brands-list-item {
			&__text {
				grid-row-start: 2;

				@include media(md) {
					grid-column-start: 1;
					grid-row-start: 1;
					text-align: right;
					justify-self: end;
				}

				h2 {

					@include media(ms) {
						text-align: right;
					}
				}
			}
		}
	}
}

.brands-logos {

	.swiper-container {

		img {
			max-width: 100%;
			opacity: 1;
			transition: opacity ease 1.5s;
		}
	}

	.swiper-container:not(.swiper-noslider) {

		img {
			max-width: 19%;
			opacity: 0;
			transition: opacity ease 1.5s;
		}
	}
}

