////
/// @group _blocks/
////

/// Описание
.section {
	position: relative;

	&--header {
		background-color: #e9e9e9;
		padding: rem(20) 0;
	}

	&--footer {
		background-color: #333;
		color: #fff;
		padding: rem(20) 0;
	}

	&--green-pic {

		.green-pic {
			width: vw(578px);
			position: absolute;
			bottom: -3%;
			right: 2%;

			@media(max-width: 1023px) {
				display: none;
			}
		}
	}
}
