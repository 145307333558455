////
/// @group _blocks/
////

/// Description
.cookie {
	position: fixed;
	bottom: -1px;
	left: 0;
	right: 0;
	background: map-get($colors, dark);
	transition: transform map-get($transitions, default);
	z-index: 1000;

	&.is-hidden {
		transform: translateY(110%);
	}

	&__content {
		padding: 0;
		margin: 0;
		font-size: rem(12px);
		line-height: 1rem;
		color: map-get($colors, border);
		max-width: 900px;
	}
}
