.tovar-block {
	margin-bottom: 35px;

	@include media(def) {
		padding-left: vw(480px);
	}

	@include media(xl) {
		padding-left: vw(160px);
	}

	&__img {
		position: relative;
		background-color: #f3f8fa;
		display: flex;
		align-items: center;
		height: 92vw;

		@include media(ms) {
			height: 100%;
		}

		@media(max-width: 440px) {
			height: 64vw;
			background-color: #fff;

			.gallery-btn {
				transform: translateX(-50%);
				left: 50%;
				width: 62%;
			}
		}

		img {
			width: 100%;
			height: 92vw;

			@media(max-width: 440px) {
				height: 64vw;
			}
		}

		.new {
			position: absolute;
			display: flex;
			top: 0;
			right: 0;
			width: 50px;
			height: 50px;
			background-color: map-get($colors, green-dark);
			align-items: center;
			justify-content: center;
			font-size: 12px;
			text-transform: uppercase;
			z-index: 3;
			color: map-get($colors, light);
		}

		@include media(ms) {

			img {
				height: auto;
			}
		}
	}

	&__item {
		display: grid;
		grid-template-columns: 1fr;
		align-items: center;
		margin-bottom: rem(32px);

		@include media(ms) {
			grid-template-columns: 240px 1fr;
		}

		@include media(def) {
			max-width: 960px;
		}

		.gallery-btn {

			&:nth-of-type(1) {
				z-index: 2;
			}
		}
	}

	&__content {
		background-color: map-get($colors, salat-light);
		text-align: center;
		padding: vw(42px) vw(45px) vw(20px);
		min-height: 260px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__code {
		font-size: 10px;
		line-height: 18px;
		letter-spacing: 0.2em;
		color: #333;
		margin-bottom: 10px;
		text-transform: uppercase;
	}

	&__name {
		max-width: 100%;
		margin: 0 auto;
		font: {
			size: rem(22px);
			weight: 500;
		};
		text-transform: uppercase;
		letter-spacing: 0.2em;
		color: #000;
		line-height: rem(32px);
		margin-bottom: 20px;

		@include media(def) {
			font-size: vw(22px);
			line-height: vw(32px);
		}

		@include media(lg) {
			font-size: vw(30px);
			line-height: vw(40px);
		}
	}

	&__text {
		max-width: 620px;
		margin: 0 auto;
		font-size: rem(18px);
		line-height: rem(30px);
		color: #333;
		margin-bottom: 24px;
	}
}
