.watch-movie {
	position: absolute;
	top: 63vw;
	width: 80px;
	right: 0;
	background: none;
	padding: 0;
	display: flex;
	cursor: pointer;
	z-index: 4;
	align-items: center;
	justify-content: space-between;
	border: none;

	@include media(ms) {
		top: vw(998px);
		width: 120px;
	}

	@include media(def) {
		top: vw(699px);
		width: vw(609px);
	}

	&__text {
		font: {
			size: vw(30px);
			weight: 500;
		};
		text-transform: uppercase;
		line-height: 30px;
		letter-spacing: 0.2em;
		max-width: 412px;
		color: map-get($colors, green-dark);
		display: none;

		@include media(def) {
			display: block;
		}
	}

	&__pic {
		flex-direction: column;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 80px;
		height: 80px;
		background-color: map-get($colors, green-dark);
		font-size: 8px;
		letter-spacing: 0.4em;
		text-transform: uppercase;
		color: map-get($colors, light);

		@include media(ms) {
			width: 120px;
			font-size: 12px;
			height: 120px;
		}

		@include media(def) {
			width: vw(160px);
			height: vw(160px);
			font-size: vw(12px);
		}

		svg {
			width: 35px;
			height: 27px;
			margin-bottom: 11px;
			fill: map-get($colors, light);

			@include media(ms) {
				width: 49px;
				height: 38px;
				margin-bottom: rem(19px);
			}

			@include media(def) {
				width: vw(49px);
				height: vw(38px);
			}
		}
	}
}
